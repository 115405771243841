import PageTitle from "../../components/common/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import DashboardContent from "./DashboardContent";
import { Typography } from "@mui/material";
import { useFeatureFlags } from "../../store/selectors/featureFlagsSelectors";
import SectionNotAvailable from "../../scaffold/ui/SectionNotAvailable";

const Dashboard = () => {
  const breadcrumbs = [
    {
      title: "dashboardPage.generalView",
      link: "",
    },
  ];

  const { t } = useTranslation();
  const featureFlags = useFeatureFlags()

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs} title={t("dashboardPage.title")} />
      {/* PLS COMMENT OUT THE NEXT LINE AFTER TESTING */}
      {
        !featureFlags.includes("DashboardPage") || featureFlags.includes("TestCompany")
          ?
          <SectionNotAvailable />
          :
          <DashboardContent />
        // <Typography>
        //   Analysetools werden zum späteren Zeitpunkt angezeigt.
        // </Typography>
      }
    </>
  );
};

export default Dashboard;
