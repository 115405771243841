import { FC, useEffect, useState } from "react";
import {
  useEditedGroupsData,
  useEditMode,
  useOnNewGroupAdd,
} from "../../../store/selectors/groupSelectors";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import EditBtn from "../../../components/common/table/EditBtn";
import SaveBtn from "../../../components/common/table/SaveBtn";
import { onCreateNewGroup, setEditMode } from "../../../store/reducers/groupsReducer";
import { useTranslation } from "react-i18next";
import { editGroupsThunk } from "../../../store/thunk/groupsThunk";
import { GroupsSearchBarStyles, SearchBarButtonContainer } from "./GroupsBlock.styles";
import NewBtn from "../../../components/common/table/NewBtn";
import { setProcessingRequest, setResponseMessage, setResponseValue } from "../../../store/reducers/tableManagementReducer";
import { t } from "i18next";
import ServerSideSearchBar from "../../../components/common/table/ServerSideSearchBar";
import { useBenefitList } from "../../../store/selectors/actionsSelectors";

interface CreateGroupButtonProps {
  isNewGroupAdd: boolean;
  onNewGroupHandler: () => void;
  adding?: boolean
}

const CreateGroupButton: FC<CreateGroupButtonProps> = ({
  isNewGroupAdd,
  onNewGroupHandler,
  adding = true
}) => {
  const editMode = useEditMode();
  if (editMode) return null;
  return (
    <NewBtn
      handleOnClick={onNewGroupHandler}
      isActive={isNewGroupAdd}
      hoverText={adding ? t("toolTip.addNewItem") : t("toolTip.cancelNewItem")}
      adding={adding}
    />
  );
};

interface GroupsSearchBarProps {
  handleOnSearch: (searchQuery: string) => void;
}

const GroupsSearchBar: FC<GroupsSearchBarProps> = ({ handleOnSearch }) => {
  const { t } = useTranslation()
  const editMode = useEditMode()
  const dispatch = useAppDispatch()
  const editedGroupsData = useEditedGroupsData()
  const isNewGroupAdd = useOnNewGroupAdd()

  const benefitList = useBenefitList()

  const handleOnSave = async () => {
    let responseOnSave = `${editedGroupsData.length} ${t("dialogModals.itemsSaved")}`
    let requestValidation = true

    const areAllEditedGroupsTaxFree = editedGroupsData.every((group: { id: number }) => {
      const matchingBenefit = benefitList.find((benefit) => benefit.id === group.id);
      return matchingBenefit?.benefitType === "TaxFreeBenefit";
    });

    editedGroupsData.forEach((singleGroup) => {
      if (areAllEditedGroupsTaxFree) {
        let totalPoints = 0
        singleGroup.actionModules.forEach((elem) => {
          totalPoints += elem.maxPoints
        })
        if (totalPoints > 500) {
          responseOnSave = t("dialogModals.groupMaxPointsOver500")
          requestValidation = false
        } else if (totalPoints < 500) {
          responseOnSave = t("dialogModals.groupMaxPointsUnder500", { maxAmount: (totalPoints / 10).toString() })
        }
      }
    })

    if (requestValidation) {
      let res = await dispatch(editGroupsThunk())
      dispatch(setResponseValue(res.meta.requestStatus))
      dispatch(setProcessingRequest(true))
    } else {
      dispatch(setResponseValue("fulfilled"))
    }
    dispatch(setResponseMessage(responseOnSave))
  };

  return (
    <GroupsSearchBarStyles>
      <ServerSideSearchBar onSearchHandler={handleOnSearch} />
      <SearchBarButtonContainer>
        {!isNewGroupAdd ? (
          <>
            <CreateGroupButton
              isNewGroupAdd={isNewGroupAdd}
              onNewGroupHandler={() => dispatch(onCreateNewGroup(true))} />
            {!editMode ? (
              <EditBtn handleOnClick={() => dispatch(setEditMode(true))} />
            ) : (
              <SaveBtn handleOnClick={handleOnSave} />
            )}
          </>
        ) : (
          <CreateGroupButton
            isNewGroupAdd={isNewGroupAdd}
            onNewGroupHandler={() => dispatch(onCreateNewGroup(false))}
            adding={false}
          />
        )}
      </SearchBarButtonContainer>
    </GroupsSearchBarStyles>
  );
};

export default GroupsSearchBar;
