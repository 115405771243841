import GroupsTable from "./GroupsTable/GroupsTable";
import GroupsSearchBar from "./GroupsSearchBar";
import {GroupsContentStyles} from "./GroupsBlock.styles";
import {MainContentWrapper} from "../../../components/common/Common.styles";
import {useEffect} from "react";
import {setResponseValue} from "../../../store/reducers/tableManagementReducer";
import {groupsApi} from "../../../api/groupsApi";
import {useAppDispatch} from "../../../hooks/StoreHooks";
import {useResponseValue} from "../../../store/selectors/tableManagementSelector";
import {useServerSidePagination} from "../../../hooks/PaginationHooks";
import { ServerSidePagination } from "../../../components/common/table/ServerSidePagination";
import { fetchActions, fetchBenefitList } from "../../../store/thunk/actionsThunk";

const GroupsBlock = () => {
  const dispatch = useAppDispatch();

  const paginatedData = useServerSidePagination(
    async (paginationParameters) => {
      const res = await groupsApi.getGroups(
        paginationParameters
      );
      return res.result;
    }
  );

  const responseValue = useResponseValue()

  useEffect(() => {
    if (responseValue.includes("fulfilled")) {
      dispatch(setResponseValue(""))
      paginatedData.updateTableItems();
    }
  }, [responseValue]);

  useEffect(() => {
    dispatch(fetchActions());
    dispatch(fetchBenefitList());
  }, []);

  return (
    <GroupsContentStyles>
      <GroupsSearchBar handleOnSearch={paginatedData.handleOnSearch}/>
      <MainContentWrapper>
        <GroupsTable paginatedData={paginatedData}/>
      </MainContentWrapper>
      <ServerSidePagination paginatedData={paginatedData}/>
    </GroupsContentStyles>
  );
};

export default GroupsBlock;
