import GroupsBlock from "./GroupsBlock/GroupsBlock";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/common/PageTitle/PageTitle";
import { CircularIndeterminate } from "../../components/common/CircularIndeterminate";
import { useProcessingRequest } from "../../store/selectors/tableManagementSelector";
import ResponseMessageDialog from "../../components/common/ResponseMessageDialog";

const breadcrumbs = [
  {
    title: "groupsPage.groups.taxFreeBenefit",
    link: "groups/taxfreebenefit",
  },
];

const Groups = () => {
  const { t } = useTranslation();
  const processingRequest = useProcessingRequest()

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}
        title={t("groupsPage.groups.title")}
      />
      <GroupsBlock />

      <CircularIndeterminate processingRequest={processingRequest} />
      <ResponseMessageDialog/>
    </>
  );
};

export default Groups;
