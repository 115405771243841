import React, { FC } from "react";
import { Container } from "../../components/common/Common.styles";
import { Typography } from "@mui/material";
import { StatisticHeaderContainer } from "./Dashboard.styles";

interface StatisticHeaderProps {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode | React.ReactNode[];
}

const StatisticHeader: FC<StatisticHeaderProps> = ({
  icon,
  title,
  children,
}) => {
  return (
    <StatisticHeaderContainer
      hPosition="space-between"
      vPosition="align-center"
    >
      <Container vPosition="center">
        {icon}
        <Typography variant="h6">{title}</Typography>
      </Container>
      <Container hPosition="flex-end">{children}</Container>
    </StatisticHeaderContainer>
  );
};

export default StatisticHeader;
