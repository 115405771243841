import { useEffect, useState, useCallback } from "react";
import {
    LargeChartWrapper,
    OptionContainer,
    RequestButton,
} from "../Dashboard.styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { chartColorList, monthsList, yearsList } from "../../actionModule/constants";
import {
    Tooltip,
    ResponsiveContainer,
    Legend,
    RadialBarChart,
    RadialBar
} from "recharts";
import { useAppDispatch } from '../../../hooks/StoreHooks'
import { fetchPresenceQuotaThunk } from "../../../store/thunk/reportingThunk";
import { usePresenceQuotaData } from "../../../store/selectors/reportingSelectors";
import { useTranslation } from "react-i18next";

export type chartDataType = {
    name: number;
    score: number;
    fill: string;
};

const PresenceQuotaChart = () => {
    const { t } = useTranslation();
    const [year, setYear] = useState<string>(new Date().getFullYear().toString());
    const [month, setMonth] = useState<string>("January");
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();

    const presenceQuotaData = usePresenceQuotaData();

    const confirmDisplayOptions = useCallback(() => {
        dispatch(fetchPresenceQuotaThunk({ year: parseInt(year, 10), month: monthsList.indexOf(month) + 1 }));
    }, [dispatch, year, month]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await dispatch(fetchPresenceQuotaThunk({ year: parseInt(year, 10), month: monthsList.indexOf(month) + 1 }));
            setLoading(false);
        };
        fetchData();
    }, [year, month, dispatch]);

    const presenceQuotaChartData: chartDataType[] = presenceQuotaData.map((elem, i) => ({
        name: elem.actionModuleId,
        score: elem.averagePresenceQuota,
        fill: chartColorList[i % 7]
    }));

    const yearItems = yearsList.map((item) => (
        <MenuItem key={item} value={item}>
            {item}
        </MenuItem>
    ));

    const monthItems = monthsList.map((item) => (
        <MenuItem key={item} value={item}>
            {item}
        </MenuItem>
    ));

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <OptionContainer>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select value={year} onChange={(e) => setYear(e.target.value)} IconComponent={ExpandMoreIcon}>
                        {yearItems}
                    </Select>
                    <FormHelperText>Choose Year</FormHelperText>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select value={month} onChange={(e) => setMonth(e.target.value)} IconComponent={ExpandMoreIcon}>
                        {monthItems}
                    </Select>
                    <FormHelperText>Choose Month</FormHelperText>
                </FormControl>
                <RequestButton onClick={confirmDisplayOptions}>{t("buttons.apply")}</RequestButton>
            </OptionContainer>

            <LargeChartWrapper>
                <ResponsiveContainer width={"100%"} height={"100%"}>
                    <RadialBarChart width={730} height={250} innerRadius="10%" outerRadius="80%" data={presenceQuotaChartData} startAngle={180} endAngle={0}>
                        <RadialBar label={{ fill: '#666', position: 'insideStart' }} background dataKey="score" />
                        <Legend iconSize={10} width={120} height={140} layout="vertical" verticalAlign="middle" align="right" />
                        <Tooltip />
                    </RadialBarChart>
                </ResponsiveContainer>
            </LargeChartWrapper>
        </div>
    );
};

export default PresenceQuotaChart;