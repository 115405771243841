import { useEffect, useMemo, useState } from "react";
import {
  LargeChartWrapper,
  OptionContainer,
  RequestButton,
} from "../Dashboard.styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import {
  monthsList,
  chartColorList
} from "../../actionModule/constants";
import {
  Tooltip,
  ResponsiveContainer,
  RadialBarChart,
  RadialBar,
  Legend
} from "recharts";
import { useAppDispatch } from '../../../hooks/StoreHooks'
import { fetchAveragePointThunk } from "../../../store/thunk/reportingThunk";
import { useAveragePointsData } from "../../../store/selectors/reportingSelectors";
import { useTranslation } from "react-i18next";

export type chartDataType = {
  name: string;
  score: number,
  fill: string;
};

const AveragePointsChart = () => {
  const { t } = useTranslation();
  const averagePointsData = useAveragePointsData();
  const [month, setMonth] = useState<string>("January");
  const [take, setTake] = useState<string>("5");
  const dispatch = useAppDispatch();

  const takeOptions: string[] = ["5", "10", "15", "20"]

  useEffect(() => {
    dispatch(fetchAveragePointThunk({ month: 1, take: 5 }));
  }, [month, take, dispatch]);

  const handleMonth = (e: SelectChangeEvent) => {
    setMonth(e.target.value);
  };

  const handleTake = (e: SelectChangeEvent) => {
    setTake(e.target.value);
  };

  const confirmDisplayOptions = () => {
    dispatch(fetchAveragePointThunk({ month: monthsList.indexOf(month) + 1, take: parseInt(take, 10) }))
  }

  const chartData: chartDataType[] = useMemo(() => {
    if (!averagePointsData || averagePointsData.length === 0) {
      return [];
    }

    return averagePointsData.map((elem, i) => ({
      name: `${elem.firstName} ${elem.lastName}`,
      score: elem.averagePoints,
      fill: chartColorList[i % 7]
    }));
  }, [averagePointsData]);

  const monthItems = useMemo(() => monthsList.map((item) => (
    <MenuItem key={item} value={item}>
      {item}
    </MenuItem>
  )), [monthsList]);

  const takeItems = useMemo(() => takeOptions.map((item) => (
    <MenuItem key={item} value={item}>
      {item}
    </MenuItem>
  )), [takeOptions]);

  return (
    <div>
      <OptionContainer>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={month}
            onChange={handleMonth}
            IconComponent={ExpandMoreIcon}
          >
            {monthItems}
          </Select>
          <FormHelperText>Choose Month</FormHelperText>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={take}
            onChange={handleTake}
            IconComponent={ExpandMoreIcon}
          >
            {takeItems}
          </Select>
          <FormHelperText>Amount of Users</FormHelperText>
        </FormControl>
        <RequestButton onClick={() => confirmDisplayOptions()}>{t("buttons.apply")}</RequestButton>
      </OptionContainer>
      <LargeChartWrapper>
        {" "}
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <RadialBarChart
            width={730}
            height={250}
            innerRadius="10%"
            outerRadius="80%"
            data={chartData}
            startAngle={180}
            endAngle={0}
          >
            <RadialBar label={{ fill: '#666', position: 'insideStart' }} background dataKey='score' />
            <Legend iconSize={10} width={120} height={140} layout='vertical' verticalAlign='middle' align="right" />
            <Tooltip />
          </RadialBarChart>
        </ResponsiveContainer>
      </LargeChartWrapper>
    </div>
  )
}

export default AveragePointsChart