import { CertificatesContentWrapper } from "./CertificatesPage.styles";
import CertificateTable from "./CertificatesTable";
import CertificatesSearchBar from "./CertificatesSearchBar";
import {certificatesApi} from "../../../api/certificatesApi";
import {useServerSidePagination} from "../../../hooks/PaginationHooks";

const CertificatesBlock = () => {
  const paginatedData = useServerSidePagination(
    async (paginationParameters) => {
      const res = await certificatesApi.getCertificates(
        paginationParameters
      );
      return res.result;
    }
  )

  return (
    <CertificatesContentWrapper>
      <CertificatesSearchBar onSearchHandler={paginatedData.handleOnSearch} />
      <CertificateTable paginatedData={paginatedData}/>
    </CertificatesContentWrapper>
  );
};

export default CertificatesBlock;
