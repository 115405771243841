import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ActionManagementUserType } from "../../types/actionManagementUserType";
import { FulfilledStatusType } from "../../types/actionItem";
import { PaginationType } from "../../types/common";

type EditUserDataPayload = {
  id: string;
  fulfilledStatus: FulfilledStatusType;
  scoreValue?: number;
  earnedPointsTotal: number;
  dateOfAction: string;
  actionModuleId: number;
};

export interface ActionsInitialState {
  editMode: boolean;
  currentActionId: string | number;
  users: ActionManagementUserType[] | [];
  filteredUsers: ActionManagementUserType[] | [];
  dataToEdit: Partial<ActionManagementUserType>[] | [];
  pagination: PaginationType;
  excelEmployeeScoresParsedData: any | null;
}

const initialState: ActionsInitialState = {
  editMode: false,
  currentActionId: 1,
  dataToEdit: [],
  users: [],
  filteredUsers: [],
  pagination: {
    totalPages: 0,
    currentPage: 1,
    itemsPerPage: 1,
  },
  excelEmployeeScoresParsedData: [],
};

const accountManagementReducer = createSlice({
  name: "actionModuleManagement",
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<ActionManagementUserType[]>) => {
      state.users = action.payload;
    },
    setUsersToEdit: (state, action: PayloadAction<EditUserDataPayload | []>) => {
      if (!Array.isArray(action.payload)) {
        const dataToEdit = state.dataToEdit;
        let userDataToEdit = dataToEdit.find(
          (data) => data.id === (action.payload as EditUserDataPayload).id
        );
        if (userDataToEdit) {
          state.dataToEdit = dataToEdit.map((dataItem) =>
            dataItem.id === (action.payload as EditUserDataPayload).id
              ? { ...dataItem, ...action.payload }
              : dataItem
          );
        } else {
          state.dataToEdit = [...dataToEdit, { ...action.payload }];
        }
      } else {
        state.dataToEdit = [];
      }
    },
    setPagination: (state, action: PayloadAction<Partial<PaginationType>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setExcelEmployeeScoresParsedData: (state, action: PayloadAction<Partial<any[]> | []>) => {
      state.excelEmployeeScoresParsedData = { ...state.excelEmployeeScoresParsedData, ...action.payload };
    },
  },
});

export const {
  setUsersToEdit,
  setUsers,
  setExcelEmployeeScoresParsedData,
} = accountManagementReducer.actions;

export default accountManagementReducer.reducer;
