import { ModalComponent } from "./ModalComponent";
import { resetResponseMessage } from "../../store/reducers/tableManagementReducer";
import { Box } from "@mui/material";
import { useResponseMessage } from "../../store/selectors/tableManagementSelector";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { useEffect, useState } from "react";

interface ResponseMessageDialogProps {
  modalMessage?: string;
  onClose?: () => void;
}

export default function ResponseMessageDialog({ modalMessage, onClose }: ResponseMessageDialogProps) {
  const dispatch = useAppDispatch();
  const responseMessage = useResponseMessage()
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (modalMessage || responseMessage) {
      setIsOpen(true);
      const timeout = setTimeout(() => {
        setIsOpen(false);
        dispatch(resetResponseMessage());
      }, 3000);
      return () => clearTimeout(timeout);
    } else {
      setIsOpen(false);
    }
  }, [modalMessage, responseMessage, dispatch]);

  const handleClose = () => {
    if (onClose) {
      onClose(); 
      dispatch(resetResponseMessage()); 
    } else {
      dispatch(resetResponseMessage()); 
    }
  };

  const messageToDisplay = modalMessage || responseMessage;

  return (
    <ModalComponent isOpen={isOpen} onClose={handleClose}>
      <Box>{messageToDisplay}</Box>
    </ModalComponent>
  );
}