import { Box } from "@mui/material";
import { useTableDataToRender } from "../../../hooks/UseTableDataToRender";
import { useBillsData, useNewBillMode } from "../../../store/selectors/billsSelectors";
import { FC, useEffect } from "react";
import BillsTableRow from "./BillsTableRow";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import { fetchSuperAdminBillingsThunk } from "../../../store/thunk/superAdminBillingsThunk";
import AddOrEditBillForm from "./edit/AddOrEditBillForm";

const BillsTableBody: FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const dispatch = useAppDispatch();
  const isNewBillAdd = useNewBillMode();
  useEffect(() => {
    dispatch(fetchSuperAdminBillingsThunk())
  }, []);
  
  const tableData = useBillsData();

  const tableDataToRender = useTableDataToRender(
    searchQuery,
    tableData,
    ["companyName", "invoiceNumber", "billType", "paymentMethod", "paymentStatus"]
  );

  const billItemsToRender = tableDataToRender.map((bill) => (
    <BillsTableRow key={bill.id} billData={bill} />
  ));

  return (
    <Box sx={{ display: "contents" }}>
      {isNewBillAdd && <AddOrEditBillForm isNewBillAdd={isNewBillAdd} />}
      {billItemsToRender}{" "}
    </Box>
  );
};

export default BillsTableBody;
