import PageTitle from "../../components/common/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import AccountManagementSearchBar from "./AccountManagementSearchBar";
import AccountManagementTable from "./AccountManagementTable/AccountManagementTable";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { useEffect, useState } from "react";
import { AccountManagementWrapper } from "./AccountManagement.styles";
import { useProcessingRequest, useResponseValue } from "../../store/selectors/tableManagementSelector";
import { CircularIndeterminate } from "../../components/common/CircularIndeterminate";
import {
  setResponseValue
} from "../../store/reducers/tableManagementReducer";
import { employeesApi } from "../../api/employeesApi";
import {GroupItem} from "../../types/groupItem";
import {groupsApi} from "../../api/groupsApi";
import ResponseMessageDialog from "../../components/common/ResponseMessageDialog";
import {useServerSidePagination} from "../../hooks/PaginationHooks";

const AccountManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const processingRequest = useProcessingRequest()
  const responseValue = useResponseValue()

  const [groupList, setGroupList] = useState<GroupItem[]>([]);

  const paginatedData = useServerSidePagination(
    async (parameters) => {
      const res = await employeesApi.getAccountManagementEmployees(
        parameters
      );
      const groups = await groupsApi.getAllGroups();
      setGroupList(groups ?? []);
      return res?.result;
    }
  );

  useEffect(() => {
    if (responseValue.includes("fulfilled")) {
      paginatedData.updateTableItems();
      dispatch(setResponseValue(""))
    }
  }, [responseValue]);

  return (
    <>
      <PageTitle
        title={t("accountManagementPage.title")}
      />
      <AccountManagementWrapper>
        <AccountManagementSearchBar handleOnSearch={paginatedData.handleOnSearch} />
        <AccountManagementTable
          data={paginatedData}
          groupList={groupList}/>
      </AccountManagementWrapper>

      <CircularIndeterminate processingRequest={processingRequest} />
      <ResponseMessageDialog />
    </>
  );
};

export default AccountManagement;
