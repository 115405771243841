import { Box, Checkbox, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import ActionButton from "../../../../../../components/common/table/ActionButton";
import { ActionItem } from "../../../../../../types/actionItem";
import { ActionListItemStyles } from "../../../GroupsBlock.styles";

interface ActionsListItemProps {
  item: ActionItem;
  isActionSelected: boolean;
  onUpdateSelectedActions: (newSelection: ActionItem[]) => void;
  selectedActions: ActionItem[];
}

const ActionsListItem: FC<ActionsListItemProps> = ({
  item,
  isActionSelected,
  onUpdateSelectedActions,
  selectedActions
}) => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      onUpdateSelectedActions([...selectedActions, item])
    } else {
      onUpdateSelectedActions(selectedActions.filter(
          (action) => action.id !== item.id
      ))
    }
  };

  return (
    <ActionListItemStyles>
      <Box>
        <ActionButton
          iconName={item.iconName}
          colorHex={item.colorHex ? item.colorHex : ""}
          actionName={item.name}
        />
        <Typography>{item.name}</Typography>
      </Box>
      <Checkbox
        checked={isActionSelected}
        onChange={onChangeHandler}
        sx={(theme) => ({
          color: theme.palette.companyMain.main,
        })}
        inputProps={{ "aria-label": "controlled" }}
      />
    </ActionListItemStyles>
  );
};

export default ActionsListItem;
