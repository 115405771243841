import {MainContentWrapper, TableWrapper} from "../../../../components/common/Common.styles";
import TaxFreeBenefitTableHeader from "./TaxFreeBenefitTableHeader";
import {Box} from "@mui/material";
import {TaxFreeBenefitUserType} from "../../../../types/taxFreeBenefitUsersType";
import TaxFreeBenefitTableRow from "./TaxFreeBenefitTableRow";
import {ServerSidePagination} from "../../../../components/common/table/ServerSidePagination";
import {PaginatedData} from "../../../../hooks/PaginationHooks";


interface Props {
  paginatedData: PaginatedData<TaxFreeBenefitUserType>;
}


export default function TaxFreeBenefitTable(props: Props) {
  return (
    <MainContentWrapper>
      <TableWrapper
        minWidth="1000px"
        columnsTemplate="1.8fr 1.6fr 1.6fr 1.6fr 1.6fr"
      >
        <TaxFreeBenefitTableHeader paginatedData={props.paginatedData}/>

        <Box sx={{display: "contents"}}>
          {props.paginatedData.items.map((item) =>
            <TaxFreeBenefitTableRow {...item}/>)
          }
        </Box>
      </TableWrapper>

      <ServerSidePagination paginatedData={props.paginatedData}/>
    </MainContentWrapper>
  )
}