import { useAppSelector } from "../../hooks/StoreHooks";

export const useAveragePointsData = () =>
  useAppSelector((state) => state.reporting.averagePoints);

export const useTotalPointsData = () =>
  useAppSelector((state) => state.reporting.totalPoints);

export const useActionModulesDoneData = () =>
  useAppSelector((state) => state.reporting.actionModulesDone);

export const usePresenceQuotaData = () =>
  useAppSelector((state) => state.reporting.presenceQuota);

export const useControlSignatureWeeklyData = () =>
  useAppSelector((state) => state.reporting.controlSignatureWeekly);

export const useControlSignatureMonthlyData = () =>
  useAppSelector((state) => state.reporting.controlSignatureMonthly);

export const useActivatedAccountsData = () =>
  useAppSelector((state) => state.reporting.activatedAccounts);

export const useEvaluationStarsData = () =>
  useAppSelector((state) => state.reporting.evaluationStars);

export const useUpcomingAnniversariesData = () =>
  useAppSelector((state) => state.reporting.upcomingAnniversaries);

export const useUpcomingBirthdaysData = () =>
  useAppSelector((state) => state.reporting.upcomingBirthdays);