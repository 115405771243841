import { GroupItem } from "../types/groupItem";
import {CallResult, doApiDelete, doApiGet, doApiPatch, doApiPost} from "./Requests";
import {PaginationParameters, PaginationResult, toQueryParameters} from "./Pagination";

export const groupsApi = {
  getGroups: (pagination: PaginationParameters): Promise<CallResult<PaginationResult<GroupItem>>> =>
    doApiGet(`/api/companies/current/taxbenefitgroups?${toQueryParameters(pagination)}`),

  getAllGroups: (): Promise<GroupItem[] | null> =>
    doApiGet("/api/companies/current/groups")
      .then(res => res.result as GroupItem[])
      .catch((error) => {
        console.log(error);
        return null
      }),

  createGroup: (groupData: Partial<GroupItem>) =>
    doApiPost("/api/companies/current/groups", groupData)
      .then(res => res)
      .catch(error => console.log(error)),

  editGroup: (id: number, groupData: Partial<GroupItem>) =>
    doApiPatch(`/api/companies/current/groups/${id}`, groupData)
      .then(res => {
        console.log(`API Response Status Code (editGroup): ${res.status}`);
        if (res.status !== 200) {
          throw new Error(`Failed to edit group with ID: ${id}. Status: ${res.status}`);
        }
        return res
      })
      .catch(error => {
        console.error('Error editing group:', error);
        throw error;
      }),

  deleteGroup: (id: number) =>
    doApiDelete(`/api/companies/current/groups/${id}`),
};
