import { Box, Modal, styled } from "@mui/material"
import { useEffect, useState } from "react";
import { CloseButton } from "./stylings/GlobalStyles";

interface ModalComponentProps {
    isOpen: boolean,
    onClose: () => void,
    children: React.ReactNode
}

export const ModalComponent = ({ isOpen, onClose, children }: ModalComponentProps) => {
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true);
            const timer = setTimeout(() => {
                setIsAnimating(false);
            }, 3000);

            return () => clearTimeout(timer);
        } else {
            setIsAnimating(false);
        }
    }, [isOpen]);

    return (
        <Modal open={isOpen} onClose={onClose}>
            <ModalContentWrapper>
                <CloseButton onClick={onClose}>X</CloseButton>
                {children}
                <BottomBar key={isOpen ? "open" : "closed"} isAnimating={isAnimating} />
            </ModalContentWrapper>
        </Modal>
    )
}

const ModalContentWrapper = styled(Box)(({ theme }) => ({
    height: "auto",
    maxHeight: "calc(100vh - 200px)",
    backgroundColor: theme.palette.background.default,
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    borderRadius: "15px 15px 15px 0",
    boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.2)",
    textAlign: 'center',
    padding: '2em 2.5em',
    transition: "transform 0.3s ease-in-out",
    "&:focus": {
        outline: "none",
    },
    animation: "fadeIn 0.5s ease-in-out",
}));

const BottomBar = ({ isAnimating }: { isAnimating: boolean }) => (
    <BottomBarWrapper isAnimating={isAnimating} />
);

const BottomBarWrapper = styled(Box)<{ isAnimating: boolean }>(({ theme, isAnimating }) => ({
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "5px",
    backgroundColor: "lightgrey",
    transition: "width 3s ease-out, background-color 0.3s ease",
    width: isAnimating ? "100%" : "0%",
    transformOrigin: "right",
    borderRadius: "2px",
}));