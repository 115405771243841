import { useTranslation } from "react-i18next"
import { Box } from '@mui/material'
import { useUpcomingBirthdaysData } from '../../../store/selectors/reportingSelectors'
import { UpcomingBirthdaysDataWrapper } from '../Dashboard.styles'
import { useEffect } from "react"
import { fetchUpcomingBirthdaysThunk } from "../../../store/thunk/reportingThunk"
import { useAppDispatch } from "../../../hooks/StoreHooks"

export const UpcomingBirthdaysChart = () => {
  const { t } = useTranslation();
  const upcomingBirthdaysData = useUpcomingBirthdaysData();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUpcomingBirthdaysThunk());
  }, [dispatch]);

  return (
    <div>
      {upcomingBirthdaysData ?
        <UpcomingBirthdaysDataWrapper>
          <Box>{t("dashboardPage.upcomingBirthdays.numberBirthdaysInCurrentMonth")}: {upcomingBirthdaysData.numberBirthdaysInCurrentMonth}</Box>
          <Box>{t("dashboardPage.upcomingBirthdays.numberBirthdaysInCurrentMonth")}: {upcomingBirthdaysData.numberBirthdaysInNextMonth}</Box>
        </UpcomingBirthdaysDataWrapper> :
        <UpcomingBirthdaysDataWrapper>
          <Box>{t("dashboardPage.noDataAvailable")}</Box>
        </UpcomingBirthdaysDataWrapper>
      }
    </div>
  )
}

