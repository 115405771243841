import { useState } from "react";
import { ActionItem } from "../../types/actionItem";
import { actionsApi } from "../../api/actionsApi";
import { ActionModuleManagementStyles, SearchBarWrapper } from "./ActionModuleManagement.styles";
import ServerSideSearchBar from "../../components/common/table/ServerSideSearchBar";
import EmployeeIncentivationType from "../../types/employeeIncentivationType";
import IncentivationTable from "./IncentivationTable";
import { Button } from "@mui/material";
import { EditIcon } from "../../assets/icons/Icons";
import SaveIcon from '@mui/icons-material/Save';
import { incentivationUsersApi } from "../../api/incentivationUsersApi";
import { setProcessingRequest } from "../../store/reducers/tableManagementReducer";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { setActionEditMode } from "../../store/reducers/actionsReducer";
import { useActionEditMode, useEditedActionInstancesData } from "../../store/selectors/actionsSelectors";
import { groupArrayOfObjects } from "../../common/logic/GeneralFunctions";
import {useServerSidePagination} from "../../hooks/PaginationHooks";

interface Props {
    actionModule: ActionItem;
}

export default function ActionModuleIncentivation(props: Props) {
    const dispatch = useAppDispatch();
    const actionEditMode = useActionEditMode();
    const editedActionInstances = useEditedActionInstancesData()

    const paginatedData = useServerSidePagination(
      async (paginationParameters) => {
          const res = await actionsApi.getEmployeesForActionModule(
            props.actionModule.id,
            paginationParameters
          );
          return res?.result;
      },
      [props.actionModule.id]
    )

    const [updatedRows, setUpdatedRows] = useState<EmployeeIncentivationType[]>([]);

    function onRowUpdated(newValue: EmployeeIncentivationType) {
        paginatedData.rawSetItems((old) => old.map((a) => a.id !== newValue.id ? a : newValue));
        setUpdatedRows((old) => old.filter((a) => a.id !== newValue.id).concat([newValue]));
    }

    async function saveUpdatedRows() {
        dispatch(setActionEditMode(false))
        dispatch(setProcessingRequest(true));
        const results = await Promise.all(
            updatedRows.map((row) =>
                incentivationUsersApi.editUser(row.id!, {
                    fulfilledStatus: row.fulfillmentStatus,
                    scoreValue: row.score,
                    actionModuleId: props.actionModule.id,
                })
            )
        );

        const groupedEditedActionInstances = groupArrayOfObjects(editedActionInstances, (c) => c.employeeId);
        for (let key in groupedEditedActionInstances) {
            for (const elem of groupedEditedActionInstances[key]) {
                await actionsApi.editActionModuleInstances(
                    elem.actionModuleId,
                    elem.employeeId,
                    elem.actionModuleInstanceId,
                    elem.fulfilledStatus)
            }
        }

        paginatedData.rawSetItems((table) => {
            for (const result of results) {
                table = table.map((a) => a.id === result.result?.id
                    ? {
                        ...a,
                        dateOfAction: result.result?.incentiveData?.dateOfAction ?? null,
                        fulfillmentStatus: result.result?.incentiveData?.fulfillmentStatus,
                        points: result.result?.incentiveData?.earnedPoints
                    } as EmployeeIncentivationType : a);
            }
            return table;
        });

        dispatch(setProcessingRequest(false));
        setUpdatedRows([]);
    }

    return <ActionModuleManagementStyles>
        <SearchBarWrapper>
            <ServerSideSearchBar onSearchHandler={paginatedData.handleOnSearch} />
            {
                actionEditMode
                    ? <Button onClick={saveUpdatedRows}>
                        <SaveIcon />
                    </Button>
                    : <Button onClick={() => dispatch(setActionEditMode(true))}>
                        <EditIcon />
                    </Button>
            }
        </SearchBarWrapper>

        <IncentivationTable
            paginatedData={paginatedData}
            actionModule={props.actionModule}
            onRowUpdated={onRowUpdated} />
    </ActionModuleManagementStyles>
}