import {FC} from "react";
import {TaxFreeBenefitUserType} from "../../../../types/taxFreeBenefitUsersType";
import {Stack, Tooltip} from "@mui/material";
import CellBox from "../../../../components/common/table/CellBox";
import BorderRow from "../../../../components/common/table/BorderRow";
import {BasicTableRowStyles} from "../../../../components/common/Common.styles";
import StatusDisplay from "../../../../components/common/table/StatusDisplay";
import TooltipTypography from "../../../../components/common/TooltipTypography";

const TaxFreeBenefitTableRow: FC<TaxFreeBenefitUserType> = ({
  firstName,
  lastName,
  position,
  employeeNumber,
  status,
  groupName,
  earnedPointsTotal,
}) => {
  const name = firstName + " " + lastName;

  return (
    <BasicTableRowStyles>
      <CellBox firstCol={true}>
        <TooltipTypography
          value={name}
        />
      </CellBox>
      <CellBox contentPosition="center">
        <TooltipTypography
          value={position}
        />
      </CellBox>
      <CellBox contentPosition="center">
        <TooltipTypography
          value={employeeNumber}
        />
      </CellBox>
      <CellBox>
        <TooltipTypography
          value={groupName ?? "-"}
        />
      </CellBox>
      <CellBox
        lastCol={true}
        contentPosition="center"
        contentDirection="column"
      >
        <Stack direction="row" alignItems="center">
          <Tooltip title={status}>
            <StatusDisplay status={status}/>
          </Tooltip>
          <TooltipTypography
            value={earnedPointsTotal}
            sxTypography={{
              color: (theme) => theme.palette.textMain.secondary,
              ml: 2
            }}
          />
        </Stack>
      </CellBox>
      <BorderRow/>
    </BasicTableRowStyles>
  );
};

export default TaxFreeBenefitTableRow;
