import BorderRow from "../../components/common/table/BorderRow";
import { useTranslation } from "react-i18next";
import { TableHeaderWrapper } from "../../components/common/Common.styles";
import React from "react";
import ServerSideTableColumnHeader from "../../components/common/table/ServerSideTableColumnHeader";
import {PaginatedData} from "../../hooks/PaginationHooks";

interface Props {
  paginatedData: PaginatedData<any>;
}

export default function BillsTableHeader(props: Props) {
  const { t } = useTranslation();

  return (
    <TableHeaderWrapper>
      <ServerSideTableColumnHeader
        fieldName="invoiceNumber"
        isSorted={true}
        label={t("tableColumnHeaders.invoiceNumber")}
        firstCol={true}
        paginatedData={props.paginatedData}
      />
      <ServerSideTableColumnHeader
        fieldName="invoiceDate"
        isSorted={true}
        label={t("tableColumnHeaders.invoiceDate")}
        paginatedData={props.paginatedData}
      />
      <ServerSideTableColumnHeader
        fieldName="billType"
        isSorted={true}
        paginatedData={props.paginatedData}
        label={t("tableColumnHeaders.billType")}
      />
      <ServerSideTableColumnHeader
        fieldName="paymentMethod"
        isSorted={true}
        paginatedData={props.paginatedData}
        label={t("tableColumnHeaders.paymentMethod")}
      />
      <ServerSideTableColumnHeader
        fieldName="paymentStatus"
        isSorted={true}
        paginatedData={props.paginatedData}
        label={t("tableColumnHeaders.status")}
      />
      <ServerSideTableColumnHeader
        fieldName="analysis"
        isSorted={false}
        label={t("tableColumnHeaders.analysis")}
      />
      <ServerSideTableColumnHeader
        fieldName="invoice"
        isSorted={false}
        label={t("tableColumnHeaders.invoice")}
        lastCol={true}
      />
      <BorderRow />
    </TableHeaderWrapper>
  );
};
