import {useEffect, useState} from "react";
import {PaginationParameters, PaginationResult} from "../api/Pagination";
import {setProcessingRequest} from "../store/reducers/tableManagementReducer";
import {useAppDispatch} from "./StoreHooks";
import {TableSortDirection} from "../common/logic/TableSortUtils";


type UpdateTableItems = () => void;
type FetchTableItems<S> = (parameters: PaginationParameters) => Promise<PaginationResult<S> | null>;
type HandleOnSearch = (search: string) => void;
type HandleOnSort = (direction: TableSortDirection | null, field: string | null) => void;
type HandleOnPageChange = (pagination: PaginationParameters) => void;
type RawSetItems<S> = (fn: SetItem<S>) => void;
type SetItem<S> = (prevState: S[]) => S[];


export interface PaginatedData<S> {
  items: S[];
  totalCount: number;
  paginationParameters: PaginationParameters;

  /**
   * Function to call when the items should be reloaded from the api
   */
  updateTableItems: UpdateTableItems;

  /**
   * Function to call to update the search term
   */
  handleOnSearch: HandleOnSearch;

  /**
   * Function to call to update sorting and ordering
   */
  handleOnSort: HandleOnSort;

  /**
   * Function to call to handle page changes
   */
  handleOnPageChange: HandleOnPageChange;

  /**
   * Function to call to change the items array without feting from the api
   */
  rawSetItems: RawSetItems<S>;
}

/**
 * This hook encapsulates all needed logic for server side pagination.
 * You only have to provide a function which does the api call. The rest is done
 * under the hood.
 * Use in combination with PaginatedData and the ServerSidePagination, ServerSideSearchBar, ServerSideTableColumnHeader
 * components
 */
export function useServerSidePagination<S>(
  fetchFunction: FetchTableItems<S>,
  deps?: unknown[]
): PaginatedData<S> {
  const dispatch = useAppDispatch();

  const [items, setItems] = useState<S[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [paginationParameters, setPaginationParameters] = useState<PaginationParameters>({
    limit: 10,
    offset: 0
  });

  useEffect(() => {
    const fetchItems = async () => {
      setItems([]);
      dispatch(setProcessingRequest(true));

      const result = await fetchFunction(paginationParameters);

      dispatch(setProcessingRequest(false));
      setItems(result?.items ?? []);
      setTotalCount(result?.totalCount ?? 0);
    };
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps ?? [], paginationParameters]);

  const updateTableItems = () => setPaginationParameters({...paginationParameters});
  const handleOnSearch = (search: string) => setPaginationParameters({...paginationParameters, search: search});
  const handleOnSort = (direction: TableSortDirection | null, field: string | null) => setPaginationParameters({
    ...paginationParameters,
    order: direction ?? undefined,
    sort: field ?? undefined,
  });

  return {
    items: items,
    totalCount: totalCount,
    paginationParameters: paginationParameters,
    updateTableItems: updateTableItems,
    handleOnSearch: handleOnSearch,
    handleOnSort: handleOnSort,
    handleOnPageChange: setPaginationParameters,
    rawSetItems: setItems
  };
}