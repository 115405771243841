import PageTitle from "../../../components/common/PageTitle/PageTitle";
import TaxFreeBenefitTable from "./TaxFreeBenefitTable/TaxFreeBenefitTable";
import {useTranslation} from "react-i18next";
import {TaxFreeBenefitContentWrapper} from "./TaxFreeBenefit.styles";
import {useProcessingRequest} from "../../../store/selectors/tableManagementSelector";
import {CircularIndeterminate} from "../../../components/common/CircularIndeterminate";
import ServerSideSearchBar from "../../../components/common/table/ServerSideSearchBar";
import {employeesApi} from "../../../api/employeesApi";
import {
  SearchWithAutocompleteWrapper
} from "../../../components/common/SearchWithAutocomplete/SearchWithAutocomplete.styles";
import {useServerSidePagination} from "../../../hooks/PaginationHooks";

const breadcrumbs = [
  {
    title: "incentivationPage.taxFreeBenefit",
    link: "incentivation/taxfreebenefit",
  },
  {
    title: "",
    link: "",
  },
];

const TaxFreeBenefit = () => {
  const {t} = useTranslation();
  const processingRequest = useProcessingRequest()

  const paginatedData = useServerSidePagination(
    async (paginationParameters) => {
      const res = await employeesApi.getTaxFreeBenefitUsers(
        paginationParameters
      );
      return res?.result;
    }
  )

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}
        title={t("incentivationPage.title")}
      />
      <TaxFreeBenefitContentWrapper>
        <SearchWithAutocompleteWrapper>
          <ServerSideSearchBar onSearchHandler={paginatedData.handleOnSearch}/>
        </SearchWithAutocompleteWrapper>
        <TaxFreeBenefitTable paginatedData={paginatedData}/>
      </TaxFreeBenefitContentWrapper>
      <CircularIndeterminate processingRequest={processingRequest}/>
    </>
  );
};

export default TaxFreeBenefit;
