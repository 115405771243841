import {createAsyncThunk} from "@reduxjs/toolkit";
import {companyManagementApi} from "../../api/companyManagementApi";
import {CompanyManagementType} from "../../types/companyManagementTypes";
import {setCompanyList} from "../reducers/billsReducer";

export const fetchCompanyList = createAsyncThunk(
  "companyBillings/getCompanyList",
  async (_, thunkApi) => {
    try {
      const users = await companyManagementApi.getAllCompanies();
      thunkApi.dispatch(setCompanyList(users as CompanyManagementType[]));
    } catch (error) {
      console.log(error);
    }
  }
);