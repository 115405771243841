import { ActionItem, ActionModuleInstancesEditItem } from "../types/actionItem";
import ActionModuleInstancesType from "../types/actionModuleInstancesType";
import EmployeeIncentivationType from "../types/employeeIncentivationType";
import { PaginationParameters, PaginationResult, toQueryParameters } from "./Pagination";
import { doApiDelete, doApiGet, doApiPatch, doApiPost } from "./Requests";

export const actionsApi = {
  getActions: () =>
    doApiGet("/api/companies/current/actionmodules")
      .then(res => res.result)
      .catch(error => console.log(error)),

  createAction: (actionData: any) =>
    doApiPost("/api/companies/current/actionmodules", actionData),

  editAction: (id: number, actionData: Partial<ActionItem>) =>
    doApiPatch(`/api/companies/current/actionmodules/${id}`, actionData),

  getAction: (id: number) =>
    doApiGet(`/api/companies/current/actionmodules/${id}`),

  getSubtypes: () =>
    doApiGet(`/api/companies/current/actionmodules/subtypes`)
      .then(res => res.result)
      .catch(error => console.log(error)),

  getBenefitList: () =>
    doApiGet(`/api/companies/current/benefits`)
      .then(res => res.result)
      .catch(error => console.log(error)),

  deleteAction: (actionId: number) =>
    doApiDelete(`/api/companies/current/actionmodules/${actionId}`),

  getEmployeesForSubtype: (subtype: string, pagination: PaginationParameters) =>
    doApiGet<PaginationResult<EmployeeIncentivationType>>(`/api/companies/current/actionmodules/subtypes/${subtype}/employees?${toQueryParameters(pagination)}`),

  getEmployeesForActionModule: (actionModuleId: number, pagination: PaginationParameters) =>
    doApiGet<PaginationResult<EmployeeIncentivationType>>(`/api/companies/current/actionmodules/${actionModuleId}/employees?${toQueryParameters(pagination)}`),

  getActionModulesForSubtype: (subtype: string) =>
    doApiGet<ActionItem[]>(`/api/companies/current/actionmodules/subtypes/${subtype}`),

  getActionModuleInstances: (actionModuleId: string, employeeId: string) =>
    doApiGet<ActionModuleInstancesType[]>(`/api/companies/current/actionmodules/${actionModuleId}/employees/${employeeId}/instances`),

  editActionModuleInstances: (actionModuleId: string, employeeId: string, instanceId: number, instanceData: Partial<ActionModuleInstancesEditItem>) =>
    doApiPatch(`/api/companies/current/actionmodules/${actionModuleId}/employees/${employeeId}/instances/${instanceId}`, {
      "fulfilledStatus": instanceData,
      "scoreValue": 0
    })
};
