import { EvaluationStarsDataWrapper, OptionContainer, RequestButton } from '../Dashboard.styles'
import { useTranslation } from "react-i18next"
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useMemo, useState } from 'react'
import { useAppDispatch } from '../../../hooks/StoreHooks'
import { fetchEvaluationStarsThunk } from '../../../store/thunk/reportingThunk'
import { Box } from '@mui/material'
import { useEvaluationStarsData } from '../../../store/selectors/reportingSelectors'

export const EvaluationStarsChart = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const evaluationStarsData = useEvaluationStarsData();
  const dispatch = useAppDispatch();

  const handleStartDateChange = (newDate: dayjs.Dayjs | null) => {
    let formattedDate = newDate?.format('YYYY-MM-DD')
    formattedDate && setStartDate(formattedDate)
  }

  const handleEndDateChange = (newDate: dayjs.Dayjs | null) => {
    let formattedDate = newDate?.format('YYYY-MM-DD')
    formattedDate && setEndDate(formattedDate)
  }

  const confirmDisplayOptions = async () => {
    if (!startDate || !endDate) return;
    await dispatch(fetchEvaluationStarsThunk({ startDate, endDate }));
  }
  const evaluationData = useMemo(() => evaluationStarsData[0], [evaluationStarsData]);

  return (
    <div>
      <OptionContainer>
        <DatePicker
          onChange={handleStartDateChange}
          format="DD.MM.YYYY"
        />
        <DatePicker
          onChange={handleEndDateChange}
          format="DD.MM.YYYY"
        />
        <RequestButton
          onClick={() => confirmDisplayOptions()}
          disabled={!startDate || !endDate}>
          {t("buttons.apply")}
        </RequestButton>
        {!evaluationStarsData.length && startDate && endDate && (
          <Box>{t("dashboardPage.evaluationStars.noData")}</Box>
        )}
      </OptionContainer>

      {evaluationData &&
        <EvaluationStarsDataWrapper>
          <Box>{t("dashboardPage.evaluationStars.responseRate")}: {evaluationData.responseRate}</Box>
          <Box>{t("dashboardPage.evaluationStars.averageStars")}: {evaluationData.averageStars}</Box>
        </EvaluationStarsDataWrapper>
      }

    </div>
  )
}
