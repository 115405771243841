import { useEffect, useMemo, useState } from "react";
import {
    LargeChartWrapper,
    OptionContainer,
    RequestButton,
} from "../Dashboard.styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { chartColorList } from "../../actionModule/constants";
import {
    Tooltip,
    ResponsiveContainer,
    Legend,
    BarChart,
    XAxis,
    YAxis,
    Bar
} from "recharts";
import { useAppDispatch } from '../../../hooks/StoreHooks'
import { fetchActionModulesDoneThunk } from "../../../store/thunk/reportingThunk";
import { useActionModulesDoneData } from "../../../store/selectors/reportingSelectors";
import { useTranslation } from "react-i18next";

export type chartDataType = {
    name: string;
    modulesDone: number,
    fill: string;
};

const ActionModulesDoneChart = () => {
    const { t } = useTranslation();
    const actionModulesDoneData = useActionModulesDoneData();
    const [take, setTake] = useState<string>("5");
    const dispatch = useAppDispatch();

    const takeOptions: string[] = ["5", "10", "15", "20"]

    useEffect(() => {
        dispatch(fetchActionModulesDoneThunk({ take: 5 }));
    }, [take, dispatch]);

    const handleTake = (e: SelectChangeEvent) => {
        setTake(e.target.value);
    };

    const confirmDisplayOptions = () => {
        dispatch(fetchActionModulesDoneThunk({ take: parseInt(take, 10) }))
    }

    const chartData: chartDataType[] = useMemo(() => {
        if (!actionModulesDoneData || actionModulesDoneData.length === 0) {
            return [];
        }

        return actionModulesDoneData.map((elem, i) => ({
            name: `${elem.firstName} ${elem.lastName}`,
            modulesDone: elem.totalDoneActionModules,
            fill: chartColorList[i % 7]
        }));
    }, [actionModulesDoneData]);

    const takeItems = useMemo(() => takeOptions.map((item) => (
        <MenuItem key={item} value={item}>
            {item}
        </MenuItem>
    )), []);

    return (
        <div>
            <OptionContainer>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                        value={take}
                        onChange={handleTake}
                        IconComponent={ExpandMoreIcon}
                    >
                        {takeItems}
                    </Select>
                    <FormHelperText>Amount of Users</FormHelperText>
                </FormControl>
                <RequestButton onClick={() => confirmDisplayOptions()}>{t("buttons.apply")}</RequestButton>
            </OptionContainer>
            <LargeChartWrapper>
                {" "}
                <ResponsiveContainer width={"100%"} height={"100%"}>
                    <BarChart
                        width={730}
                        height={350}
                        data={chartData}
                    >
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Tooltip />
                        <Bar dataKey="modulesDone" fill="#8884d8" />
                    </BarChart >
                </ResponsiveContainer>
            </LargeChartWrapper>
        </div>
    )
}

export default ActionModulesDoneChart