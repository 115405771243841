import { Box, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AddActionButton from "./AddActionButton";
import ActionButtonsList from "../../../../../../components/common/table/ActionButtonsList";
import { GroupItem } from "../../../../../../types/groupItem";
import { useAppDispatch } from "../../../../../../hooks/StoreHooks";
import {
  onCreateNewGroup,
  setEditedGroupsData,
} from "../../../../../../store/reducers/groupsReducer";
import { calculateMaxPoints } from "../../../../../../common/logic/ActionItemUtils";
import {
  createGroupThunk,
  deleteGroupThunk,
} from "../../../../../../store/thunk/groupsThunk";
import { compareComplexData } from "../../../../../../common/logic/GroupsUtils";
import DeleteBtn from "../../../../../../components/common/table/DeleteBtn";
import SaveBtn from "../../../../../../components/common/table/SaveBtn";
import { useTranslation } from "react-i18next";
import BorderRow from "../../../../../../components/common/table/BorderRow";
import {
  ActionButtonWrapperOnEditStyles,
  AddEditGroupRowStyles,
  GroupNameInputStyles,
  GroupRowBtnsStyles,
} from "../../../GroupsBlock.styles";
import CellBox from "../../../../../../components/common/table/CellBox";
import StatusDisplay from "../../../../../../components/common/table/StatusDisplay";
import { StatusTypes } from "../../../../../../types/common";
import { setProcessingRequest, setResponseMessage, setResponseValue } from "../../../../../../store/reducers/tableManagementReducer";
import {ActionItem} from "../../../../../../types/actionItem";

interface AddOrEditGroupFormProps {
  groupItem?: GroupItem;
  isNewGroupAdd?: boolean;
}

const AddOrEditGroupForm = ({
  groupItem,
  isNewGroupAdd,
}: AddOrEditGroupFormProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [groupData, setGroupData] = useState<GroupItem>({
    name: "",
    id: 0,
    actionModules: [],
    maxPoints: 0,
    staff: 0,
    status: StatusTypes.Active,
    type: "TaxBenefit",
    actionModuleIds: [],
    companyId: 0,
  });

  const { name, id, actionModules, maxPoints, staff, status } = groupData;

  useEffect(() => {
    if (groupItem) setGroupData(groupItem);
  }, [groupItem]);

  useEffect(() => {
    setGroupData((state) => ({
      ...state,
      maxPoints: calculateMaxPoints(actionModules),
    }))
  }, [actionModules]);

  const handleOnBlur = (group?: GroupItem) => {
    if (
      !compareComplexData(
        group ? group : (groupItem as GroupItem),
        groupData
      ) &&
      !isNewGroupAdd
    ) {
      if (group) {
        dispatch(setEditedGroupsData(group));
      } else {
        dispatch(setEditedGroupsData(groupData));
      }
    }
  };

  const updateSelectedActionModules = (newSelection: ActionItem[]) => {
    setGroupData((state) => ({
      ...state,
      actionModules: newSelection,
      actionModuleIds: newSelection.map((i) => i.id)
    }))
  }

  const handleOnGroupSave = async () => {
    let responseOnSave = t("dialogModals.itemCreatedGroup")

    let res = await dispatch(createGroupThunk(groupData))
    dispatch(setResponseValue(res.type))
    dispatch(setProcessingRequest(true))
    dispatch(setResponseMessage(responseOnSave))
    if (!name) return;
  };

  const handleDeleteGroup = async () => {
    if (isNewGroupAdd) {
      dispatch(onCreateNewGroup(false))
    } else {
      dispatch(setProcessingRequest(true))
      let res = await dispatch(deleteGroupThunk(id))
      dispatch(setResponseValue(res.type))
      dispatch(setResponseMessage(t("dialogModals.itemDeletedGroup")))
    }
  };

  const handleActionButtonDelete = (actionId: number) => {
    const actionModulesData = {
      actionModules: groupData.actionModules.filter(
        (btn) => btn.id !== actionId
      ),
      actionModuleIds: groupData.actionModuleIds.filter(
        (id) => id !== actionId
      ),
    };
    setGroupData({
      ...groupData,
      ...actionModulesData,
    });
    handleOnBlur({
      ...groupData,
      ...actionModulesData,
    });
  };

  return (
    <AddEditGroupRowStyles>
      <form action="">
        <CellBox sx={{ justifyContent: "flex-start", paddingLeft: "35px" }}>
          <GroupNameInputStyles>
            <TextField
              value={name}
              placeholder={t("groupsPage.placeholders.incentiveGroupName")}
              onChange={(e) =>
                setGroupData({ ...groupData, name: e.target.value })
              }
              onBlur={() => {
                handleOnBlur();
              }}
            />
          </GroupNameInputStyles>
        </CellBox>
        <ActionButtonWrapperOnEditStyles>
          <AddActionButton
            handleOnEdit={handleOnBlur}
            selectedActions={actionModules}
            setSelectedActions={updateSelectedActionModules}
          />
          <ActionButtonsList
            isEditMode={true}
            groupActions={actionModules}
            handleActionButtonDelete={handleActionButtonDelete}
          />
        </ActionButtonWrapperOnEditStyles>
        <Box>
          <Typography>{maxPoints}</Typography>
        </Box>
        <Box>
          <Typography>{staff}</Typography>
        </Box>
        <Box>
          <Typography>
            <StatusDisplay status={status} />
          </Typography>
        </Box>
        <CellBox lastCol={true} contentPosition="center">
          <GroupRowBtnsStyles>
            {" "}
            {isNewGroupAdd && (
              <SaveBtn
                handleOnClick={handleOnGroupSave}
                hoverText={t("label.saveGroup")}
              />
            )}
            <DeleteBtn
              isCreateNewMode={isNewGroupAdd}
              dialogMessage={t("dialogModals.confirmDeleteGroup")}
              handleOnDelete={handleDeleteGroup}
            />
          </GroupRowBtnsStyles>
        </CellBox>
        <BorderRow />
      </form>
    </AddEditGroupRowStyles>
  );
};

export default AddOrEditGroupForm;
