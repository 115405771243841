import BillsTableHeader from "./BillsTableHeader";
import {
  MainContentWrapper,
  TableWrapper,
} from "../../components/common/Common.styles";
import {Box} from "@mui/material";
import BillsTableRow from "./BillsTableRow";
import {BillItem} from "../../types/bills";
import {ServerSidePagination} from "../../components/common/table/ServerSidePagination";
import {PaginatedData} from "../../hooks/PaginationHooks";

interface Props {
  data: PaginatedData<BillItem>;
}

export default function BillsTable(props: Props) {
  return (
    <MainContentWrapper>
      <TableWrapper
        minWidth="1330px"
        columnsTemplate="1.25fr 1fr 2fr 1.5fr 1.5fr 1fr 1fr"
      >
        <BillsTableHeader paginatedData={props.data}/>
        <Box sx={{display: "contents"}}>
          {props.data.items.map((item) => (<BillsTableRow
            key={item.id}
            billData={item}/>))}
        </Box>
      </TableWrapper>

      <ServerSidePagination paginatedData={props.data}/>
    </MainContentWrapper>
  );
};
