import { useAppDispatch } from "../../../hooks/StoreHooks";
import { onCreateNewCertificate, setCertificatesEditMode } from "../../../store/reducers/certificatesReducer";
import SaveBtn from "../../../components/common/table/SaveBtn";
import EditBtn from "../../../components/common/table/EditBtn";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SearchWithAutocompleteWrapper } from "../../../components/common/SearchWithAutocomplete/SearchWithAutocomplete.styles";
import { useCertificatesEditMode, useOnNewCertificateAdd } from "../../../store/selectors/certificatesSelectors";
import { editCertificatesThunk } from "../../../store/thunk/certificatesThunk";
import NewBtn from "../../../components/common/table/NewBtn";
import { SearchBarButtonContainer } from "./CertificatesPage.styles";
import { setProcessingRequest, setResponseMessage, setResponseValue } from "../../../store/reducers/tableManagementReducer";
import ServerSideSearchBar from "../../../components/common/table/ServerSideSearchBar";

interface CertificatesSearchBarProps {
  onSearchHandler: (searchQuery: string) => void;
}

const CertificatesSearchBar: FC<CertificatesSearchBarProps> = ({ onSearchHandler }) => {
  const { t } = useTranslation();
  const editMode = useCertificatesEditMode();
  const dispatch = useAppDispatch();
  const isNewCertificateToAdd = useOnNewCertificateAdd();

  const handleOnSave = async () => {
    dispatch(setProcessingRequest(true))
    let res = await dispatch(editCertificatesThunk());
    dispatch(setResponseValue(res.type))
    dispatch(setResponseMessage(`${t("dialogModals.itemsSaved")}`))
  };

  return (
    <SearchWithAutocompleteWrapper>
      <ServerSideSearchBar
        onSearchHandler={onSearchHandler}
      />
      <SearchBarButtonContainer>
        {!editMode && <NewBtn
            handleOnClick={() => dispatch(onCreateNewCertificate(true))}
            isActive={isNewCertificateToAdd}/>
        }
        {!editMode
          ? <EditBtn handleOnClick={() => dispatch(setCertificatesEditMode(true))}/>
          : <SaveBtn handleOnClick={handleOnSave} title={t("buttons.save")}/>
        }
      </SearchBarButtonContainer>
    </SearchWithAutocompleteWrapper>
  );
};

export default CertificatesSearchBar;
