import { useCallback, useEffect, useMemo, useState } from "react";
import {
    LargeChartWrapper,
    OptionContainer,
    RequestButton,
} from "../Dashboard.styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { chartColorList } from "../../actionModule/constants";
import {
    Tooltip,
    ResponsiveContainer,
    Legend,
    BarChart,
    XAxis,
    YAxis,
    Bar
} from "recharts";
import { useAppDispatch } from '../../../hooks/StoreHooks'
import { fetchTotalPointThunk } from "../../../store/thunk/reportingThunk";
import { useTotalPointsData } from "../../../store/selectors/reportingSelectors";
import { useTranslation } from "react-i18next";

export type chartDataType = {
    name: string;
    score: number,
    fill: string;
};

const TotalPointsChart = () => {
    const { t } = useTranslation();
    const totalPointsData = useTotalPointsData();
    const [take, setTake] = useState<string>("5");
    const dispatch = useAppDispatch();

    const takeOptions: string[] = ["5", "10", "15", "20"]

    useEffect(() => {
        dispatch(fetchTotalPointThunk({ take: 5 }));
    }, [take, dispatch]);

    const handleTake = (e: SelectChangeEvent) => {
        setTake(e.target.value);
    };

    const confirmDisplayOptions = useCallback(() => {
        dispatch(fetchTotalPointThunk({ take: parseInt(take, 10) }));
    }, [dispatch, take]);

    const chartData = useMemo(() => {
        return totalPointsData.map((elem, i) => ({
            name: `${elem.firstName} ${elem.lastName}`,
            score: elem.totalPoints,
            fill: chartColorList[i % 7],
        }));
    }, [totalPointsData]);

    const takeItems = takeOptions.map((item) => (
        <MenuItem key={item} value={item}>
            {item}
        </MenuItem>
    ));

    return (
        <div>
            <OptionContainer>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                        value={take}
                        onChange={handleTake}
                        IconComponent={ExpandMoreIcon}
                    >
                        {takeItems}
                    </Select>
                    <FormHelperText>Amount of Users</FormHelperText>
                </FormControl>
                <RequestButton onClick={() => confirmDisplayOptions()}>{t("buttons.apply")}</RequestButton>
            </OptionContainer>
            <LargeChartWrapper>
                {" "}
                <ResponsiveContainer width={"100%"} height={"100%"}>
                    <BarChart
                        width={730}
                        height={350}
                        data={chartData}
                    >
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Legend />
                        <Tooltip />
                        <Bar dataKey="score" fill="#8884d8" />
                    </BarChart >
                </ResponsiveContainer>
            </LargeChartWrapper>
        </div>
    )
}

export default TotalPointsChart