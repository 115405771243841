import BorderRow from "../../../components/common/table/BorderRow";
import {useTranslation} from "react-i18next";
import {TableHeaderWrapper} from "../../../components/common/Common.styles";
import ServerSideTableColumnHeader from "../../../components/common/table/ServerSideTableColumnHeader";
import {PaginatedData} from "../../../hooks/PaginationHooks";

interface Props {
  paginatedData: PaginatedData<any>;
}

export default function CertificatesTableHeader(props: Props) {
  const {t} = useTranslation();

  return (
    <TableHeaderWrapper>
      <ServerSideTableColumnHeader
        fieldName="documentName"
        isSorted={true}
        label={t("tableColumnHeaders.documentName")}
        firstCol={true}
        paginatedData={props.paginatedData}
      />
      <ServerSideTableColumnHeader
        fieldName="name"
        isSorted={true}
        label={t("tableColumnHeaders.employeeFullName")}
        paginatedData={props.paginatedData}
      />
      <ServerSideTableColumnHeader
        fieldName="endDate"
        isSorted={true}
        label={t("tableColumnHeaders.endDate")}
        paginatedData={props.paginatedData}
      />
      <ServerSideTableColumnHeader
        fieldName="notificationDate"
        isSorted={true}
        label={t("tableColumnHeaders.notificationDate")}
        paginatedData={props.paginatedData}
      />
      <ServerSideTableColumnHeader
        fieldName="documents"
        isSorted={false}
        label={t("tableColumnHeaders.documents")}
        paginatedData={props.paginatedData}
      />
      <ServerSideTableColumnHeader
        fieldName="employeeAccountStatus"
        isSorted={false}
        label={t("tableColumnHeaders.status")}
        paginatedData={props.paginatedData}
      />
      <BorderRow/>
    </TableHeaderWrapper>
  );
};
