import React, { useCallback, useState } from 'react';
import { theme } from '../../../theme';
import StatisticHeader from '../StatisticHeader';
import { ExpandableWidgetContainer, WidgetContent, WidgetExpand, WidgetHeader } from '../Dashboard.styles';

interface ExpandableWidgetProps {
    widgetTitle: string;
    IconComponent: React.ElementType;
    children: React.ReactNode;
}

const ExpandableWidget: React.FC<ExpandableWidgetProps> = ({ widgetTitle, IconComponent, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, []);

    return (
        <ExpandableWidgetContainer>
            <WidgetHeader onClick={handleToggle}>
                <StatisticHeader
                    icon={<IconComponent color={theme.palette.companyMain.main} />}
                    title={widgetTitle}>
                    <WidgetExpand isOpen={isOpen}>
                        &#9654;
                    </WidgetExpand>
                </StatisticHeader>
            </WidgetHeader>
            <WidgetContent isOpen={isOpen}>
                <div>{children}</div>
            </WidgetContent>
        </ExpandableWidgetContainer>
    );
};

export default ExpandableWidget;