import { Box, Button, IconButton, styled } from "@mui/material";
import { Container } from "../../components/common/Common.styles";
import { theme } from "../../theme";

export const DashboardContentStyles = styled(Box)({
  width: "100%",
  margin: "11px 0 0 0",
});

const gridGapLg = 40;

export const DashboardGrid = styled(Container)({
  marginTop: "22px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: `${gridGapLg}px`,
});

export const DashboardSelectBar = styled(Container)({
  maxWidth: "867px",
  gap: "30px",
  [theme.breakpoints.down(996)]: {
    flexWrap: "wrap",
  },
});

export const DateSelectStyles = styled(Container)({
  ".MuiTypography-root": {
    marginRight: "10px",
  },
  "& > .MuiBox-root": {
    border: `1px solid ${theme.palette.border.main}`,
    "&:before": {
      borderRight: `1px solid ${theme.palette.border.main}`,
    },
  },
});

export const StatisticsWrapper = styled(Container)({
  gap: `${gridGapLg}px`,
  [theme.breakpoints.down(1444)]: {
    flexWrap: "wrap",
  },
});

const statisticsCardsGeneralStyles = {
  background: theme.palette.backgroundColor.main,
  border: `1px solid ${theme.palette.border.main}`,
};

export const CheckBoxContainer = styled(Container)({
  height: "24px",
  boxSizing: "border-box",
  padding: "0 7px",
  width: "fit-content",
  ...statisticsCardsGeneralStyles,
  ".MuiTypography-root": {
    marginRight: "5px",
  },
});

export const StatisticHeaderContainer = styled(Container)({
  "& > .MuiBox-root:first-child": {
    width: "100%",
  },
  "& > .MuiBox-root:last-child": {
    width: "fit-content",
  },
  "& .MuiTypography-root": {
    marginLeft: "20px",
  },
});

export const GeneralInfoContainer = styled(Container)({
  "& > .MuiBox-root:first-child": {
    width: "100%",
  },
  "& > .MuiBox-root:last-child": {
    width: "fit-content",
  },
  "& .MuiTypography-root": {
    marginLeft: "20px",
  },
  backgroundColor: theme.palette.backgroundColor.main,
  padding: "10px",
  display: "flex",
  justifyContent: "flex-end"
});

export const ActivatedAccountsBox = styled(Container)({
  border: `1px solid ${theme.palette.backgroundColor.secondary}`,
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px",
  width: "30% !important",
  fontSize: "large"
})

export const AccountCounter = styled(Box)({
  fontWeight: "700",
  fontSize: "x-large",
  display: "flex"
})

export const ActivatedAccountsText = styled(Box)({
  width: "70%"
})

export const ActiveUsersText = styled(Box)({
  color: theme.palette.textMain.main
})

export const TotalUsersText = styled(Box)({
  color: theme.palette.textMain.secondary
})

export const StatisticInfoStyles = styled(Box)({
  "& > .MuiBox-root > .MuiTypography-root": {
    width: "100%",
  },
  "& > .MuiBox-root > .MuiBox-root": {
    marginLeft: "10px",
    width: "50%",
    minWidth: "20px",
  },
  "& > .MuiTypography-root": {
    color: theme.palette.textMain.secondary,
    textAlign: "right",
  },
  "& .MuiTypography-root": {
    marginLeft: "0!important",
  },
});

export const CrossedCircleIcon = styled("span")({
  position: "relative",
  display: "block",
  width: "20px",
  height: "20px",
  boxSizing: "border-box",
  borderRadius: "50%",
  border: `2px solid ${theme.palette.textMain.main}`,
  "&:before": {
    content: "''",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(-60deg)",
    width: "150%",
    height: "2px",
    background: theme.palette.textMain.main,
  },
});

export const StatisticCardStyles = styled(Box)({
  width: `calc(33.333% - ${(gridGapLg * 2) / 3}px)`,
  padding: "46px 56px 56px 36px",
  ...statisticsCardsGeneralStyles,
  [theme.breakpoints.down(1444)]: {
    width: "60%",
  },
});

export const LargeStatisticsContainer = styled(Box)({
  padding: "51px 53px 29px 31px",
  width: `calc(55.82% - ${gridGapLg / 2}px)`,
  ...statisticsCardsGeneralStyles,
  [theme.breakpoints.down(1444)]: {
    width: "100%",
  },
  "& > .MuiBox-root:first-child": {
    marginBottom: "25px",
    ".MuiTypography-root": {
      marginLeft: "30px",
    },
  },
});

export const ChartTitle = styled(Box)({
  textAlign: "center",
  color: theme.palette.textMain.secondary,
  fontWeight: "800",
  fontSize: "x-large"
})

export const MultipleChartsOuterWrapper = styled(Box)({
  position: "relative",
  margin: "0 auto",
  width: "100%",
  maxHeight: "350px",
  display: "flex",
  paddingTop: "1em",
  paddingBottom: "2em"
});

export const MultipleChartsInnerWrapper = styled(Box)({
  width: "100%"
});

export const ChartContainer = styled(Box)({
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  "& > div": {
    top: "0",
    left: "0",
    width: "50%",
    height: "100%",
  },
});

export const LargeChartWrapper = styled(Box)({
  position: "relative",
  width: "100%",
  paddingTop: "44.478%",
  "& > div": {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
});

export const MidStatisticsContainer = styled(Box)({
  padding: "50px 56px 50px 38px",
  width: `calc(44.18% - ${gridGapLg / 2}px)`,
  ...statisticsCardsGeneralStyles,
  [theme.breakpoints.down(1444)]: {
    width: "70%",
  },
});

export const OptionContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0"
});

export const RequestButton = styled(Button)({
  border: `1px solid ${theme.palette.companyMain.main}`,
  borderRadius: "10px",
  height: "50%",
  width: "20%",
  marginBottom: "2%"
});

export const EvaluationStarsDataWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px",
  margin: "10px",
  fontSize: "larger",
  fontWeight: "600"
})

export const UpcomingBirthdaysDataWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px",
  margin: "10px",
  fontSize: "larger",
  fontWeight: "600"
})

export const ExpandableWidgetContainer = styled(Box)({
  width: '100%',
  backgroundColor: 'white',
  border: '1px solid #ddd',
  borderRadius: '5px',
  margin: '10px 0',
  overflow: 'hidden',
  padding: '1em 1em 0px 1em'
});

export const WidgetHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  cursor: 'pointer',
  padding: '15px 20px',
  borderRadius: '10px',
});

export const WidgetExpand = styled(IconButton)<{ isOpen: boolean }>(({ isOpen, theme }) => ({
  transition: 'transform 0.3s ease-in-out',
  transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
  color: theme.palette.primary.main,
}));

export const WidgetContent = styled(Box)(({ isOpen }: { isOpen: boolean }) => ({
  padding: '10px',
  overflow: 'hidden',
  maxHeight: isOpen ? '1000px' : '0px',
  opacity: isOpen ? 1 : 0,
  visibility: isOpen ? 'visible' : 'hidden',
  transition: 'max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0s linear 0.5s'
}));

export const UpcomingAnniversariesDataWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px",
  margin: "10px",
  fontSize: "larger",
  fontWeight: "600"
})