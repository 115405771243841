import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Button, Typography } from "@mui/material";
import { TableSortDirection } from "../../../common/logic/TableSortUtils";
import {ReactNode, useEffect, useState} from "react";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { TableColumnHeaderWrapper } from "./ServerSideTableColumnHeader.styles";
import {PaginatedData} from "../../../hooks/PaginationHooks";

interface Props {
    label: string;
    isSorted?: boolean;
    fieldName?: string;
    prevIcon?: ReactNode;
    firstCol?: boolean;
    lastCol?: boolean;
    paginatedData?: PaginatedData<any>
}

export default function ServerSideTableColumnHeader(props: Props) {
    const [sortDirection, setSortDirection] = useState<TableSortDirection | null>();

    useEffect(() => {
        if (props.fieldName !== props.paginatedData?.paginationParameters.sort) {
            setSortDirection(null);
        }
        else {
            setSortDirection(props.paginatedData?.paginationParameters.order);
        }

    }, [
      props.paginatedData?.paginationParameters.order,
      props.paginatedData?.paginationParameters.sort,
      props.fieldName
    ]);

    function toggleSort() {
        if (!props.paginatedData?.handleOnSort || !props.fieldName) return;

        if (sortDirection == null) {
            props.paginatedData?.handleOnSort("asc", props.fieldName);
        } else if (sortDirection === "asc") {
            props.paginatedData?.handleOnSort("desc", props.fieldName);
        } else {
            props.paginatedData?.handleOnSort(null, null);
        }
    }

    return <TableColumnHeaderWrapper style={{
        ...(props.firstCol && {paddingLeft: "25px"}),
        ...(props.lastCol && {paddingRight: "25px"}),
    }}>
        <Typography style={{ flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap" }} display={"inline-block"}>{props.label}</Typography>

        {props.isSorted && sortDirection == null && <Button onClick={toggleSort}>
            <SwapVertIcon sx={{ fontSize: "1.5rem" }} />
        </Button>
        }
        {props.isSorted && sortDirection === "asc" && <Button onClick={toggleSort}>
            <ArrowDownwardIcon sx={{ fontSize: "1.5rem" }} />
        </Button>
        }
        {props.isSorted && sortDirection === "desc" && <Button onClick={toggleSort}>
            <ArrowUpwardIcon sx={{ fontSize: "1.5rem" }} />
        </Button>}
    </TableColumnHeaderWrapper>;
};
