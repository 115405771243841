import { AccountManagementUserType } from "../types/accountManagementUsersType";
import { EmployeeGeneral } from "../types/employeeType";
import { PaginationParameters, PaginationResult, toQueryParameters } from "./Pagination";
import { doApiDelete, doApiGet, doApiPatch, doApiPost } from "./Requests";
import {TaxFreeBenefitUserType} from "../types/taxFreeBenefitUsersType";

export const employeesApi = {
  getAccountManagementEmployees: (pagination: PaginationParameters) =>
    doApiGet<PaginationResult<AccountManagementUserType>>(`/api/companies/current/account-management/employees?${toQueryParameters(pagination)}`),

  createEmployee: (userData: Partial<AccountManagementUserType>[]) =>
    doApiPost("/api/companies/current/employees", userData)
      .then(res => res.result)
      .catch(error => console.log(error)),

  editAccountManagementEmployee: (id: string, userData: Partial<AccountManagementUserType>) => {
    doApiPatch(`/api/employee/${id}/accountmanagement`, userData)
      .then(res => res)
      .catch(error => console.log(error))
  },

  deleteEmployee: (userId: string) => {
    doApiDelete(`/api/companies/current/employees/${userId}`)
      .then(res => res)
      .catch(error => console.log(error))
  },

  getTaxFreeBenefitUsers: (pagination: PaginationParameters) =>
    doApiGet<PaginationResult<TaxFreeBenefitUserType>>(`/api/companies/current/taxfreebenefits/employees?${toQueryParameters(pagination)}`),

  editTaxFreeBenefitUser: (
    userId: string,
    { groupId, status }: { groupId: number; status: string }
  ) =>
    doApiPatch(`/api/employee/${userId}`, { groupId, status })
      .then(res => res)
      .catch(error => console.log(error)),
};
