import { useEffect, useMemo, useState } from "react";
import {
    ChartTitle,
    MultipleChartsInnerWrapper,
    MultipleChartsOuterWrapper,
    OptionContainer,
    RequestButton,
    ChartContainer,
} from "../Dashboard.styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { chartColorList, monthsList, yearsList } from "../../actionModule/constants";
import {
    Tooltip,
    ResponsiveContainer,
    Legend,
    RadialBar,
    PieChart,
    Pie,
    Cell,
    BarChart,
    XAxis,
    Bar,
    YAxis
} from "recharts";
import { useAppDispatch } from '../../../hooks/StoreHooks'
import { fetchControlSignatureMonthlyThunk, fetchControlSignatureWeeklyThunk } from "../../../store/thunk/reportingThunk";
import { useControlSignatureMonthlyData, useControlSignatureWeeklyData } from "../../../store/selectors/reportingSelectors";
import { useTranslation } from "react-i18next";

export type weeklyChartDataType = {
    name: string,
    confirmed: number,
    notConfirmed: number,
    noResponse: number,
};

export type monthlyChartDataType = {
    name: string;
    value: number,
};

const ControlSignatureChart = () => {
    const { t } = useTranslation();
    const controlSignatureMonthlyData = useControlSignatureMonthlyData();
    const controlSignatureWeeklyData = useControlSignatureWeeklyData();
    const [year, setYear] = useState<string>((new Date().getFullYear().toString()));
    const [month, setMonth] = useState<string>("January");
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchControlSignatureMonthlyThunk({ year: new Date().getFullYear(), month: 1 }));
        dispatch(fetchControlSignatureWeeklyThunk({ year: new Date().getFullYear(), month: 1 }));
    }, [dispatch]);

    const handleYear = (e: SelectChangeEvent) => {
        setYear(e.target.value);
    };

    const handleMonth = (e: SelectChangeEvent) => {
        setMonth(e.target.value);
    };

    const confirmDisplayOptions = () => {
        dispatch(fetchControlSignatureMonthlyThunk({ year: parseInt(year, 10), month: monthsList.indexOf(month) + 1 }))
        dispatch(fetchControlSignatureWeeklyThunk({ year: parseInt(year, 10), month: monthsList.indexOf(month) + 1 }))
    }

    const weeklyChartData = useMemo(() => {
        return controlSignatureWeeklyData.map((weeklyData, i) => ({
            name: (i + 1).toString(),
            confirmed: weeklyData.confirmed,
            notConfirmed: weeklyData.notConfirmed,
            noResponse: weeklyData.noResponse,
        }));
    }, [controlSignatureWeeklyData]);

    const monthlyChartData: monthlyChartDataType[] = useMemo(() => {
        return controlSignatureMonthlyData[0] ? [
            { name: t("dashboardPage.controlSignature.confirmed"), value: controlSignatureMonthlyData[0].checkedCount },
            { name: t("dashboardPage.controlSignature.notConfirmed"), value: controlSignatureMonthlyData[0].notCheckedCount },
            { name: t("dashboardPage.controlSignature.noResponse"), value: controlSignatureMonthlyData[0].untouchedCount },
        ] : [];
    }, [controlSignatureMonthlyData, t]);

    const yearItems = useMemo(() => {
        return yearsList.map((item) => (
            <MenuItem key={item} value={item}>
                {item}
            </MenuItem>
        ));
    }, []);

    const monthItems = useMemo(() => {
        return monthsList.map((item) => (
            <MenuItem key={item} value={item}>
                {item}
            </MenuItem>
        ));
    }, []);

    return (
        <div>
            <OptionContainer>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                        value={year}
                        onChange={handleYear}
                        IconComponent={ExpandMoreIcon}
                    >
                        {yearItems}
                    </Select>
                    <FormHelperText>{t("dashboardPage.chooseMonth")}</FormHelperText>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                        value={month}
                        onChange={handleMonth}
                        IconComponent={ExpandMoreIcon}
                    >
                        {monthItems}
                    </Select>
                    <FormHelperText>{t("dashboardPage.chooseYear")}</FormHelperText>
                </FormControl>
                <RequestButton onClick={() => confirmDisplayOptions()}>{t("buttons.apply")}</RequestButton>
            </OptionContainer>

            <MultipleChartsOuterWrapper>
                <MultipleChartsInnerWrapper>
                    <ChartTitle>
                        {t("dashboardPage.controlSignature.sections")}
                    </ChartTitle>
                    <ChartContainer>
                        <ResponsiveContainer width={"100%"} height={"100%"}>
                            <BarChart width={730} height={350} data={weeklyChartData}>
                                <XAxis dataKey="name" />
                                <YAxis label={{ value: t("dashboardPage.controlSignature.amount"), angle: -90, position: 'outside' }} />
                                <Tooltip />
                                <Legend />
                                <Bar
                                    dataKey="confirmed"
                                    name={t("dashboardPage.controlSignature.confirmed")}
                                    fill={chartColorList[0]}
                                />
                                <Bar
                                    dataKey="notConfirmed"
                                    name={t("dashboardPage.controlSignature.notConfirmed")}
                                    fill={chartColorList[1]}
                                />
                                <Bar
                                    dataKey="noResponse"
                                    name={t("dashboardPage.controlSignature.noResponse")}
                                    fill={chartColorList[2]}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </ChartContainer>
                </MultipleChartsInnerWrapper>

                <MultipleChartsInnerWrapper>
                    <ChartTitle>
                        {t("dashboardPage.controlSignature.total")}
                    </ChartTitle>
                    <ChartContainer>
                        <ResponsiveContainer width={"95%"} height={"100%"}>
                            <PieChart width={730} height={250}>
                                <Pie data={monthlyChartData} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label >
                                    {
                                        monthlyChartData.map((entry, index) => <Cell fill={chartColorList[index % chartColorList.length]} />)
                                    }
                                </Pie>
                                <RadialBar label={{ fill: '#666', position: 'insideStart' }} background dataKey='quota' />
                                <Legend iconSize={10} width={160} height={140} layout='vertical' verticalAlign='middle' align="right" />
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </ChartContainer>
                </MultipleChartsInnerWrapper>
            </MultipleChartsOuterWrapper>

        </div>
    )
}

export default ControlSignatureChart
