import {
  DashboardContentStyles,
  DashboardGrid,
  StatisticsWrapper,
} from "./Dashboard.styles";
import { Container } from "../../components/common/Common.styles";
import AveragePointsChart from "./widgets/AveragePointsChart";
import TotalPointsChart from "./widgets/TotalPointsChart";
import ActionModulesDoneChart from "./widgets/ActionModulesDoneChart";
import PresenceQuotaChart from "./widgets/PresenceQuotaChart";
import ControlSignatureChart from "./widgets/ControlSignatureChart";
import GeneralInfoData from "./widgets/GeneralInfoData";
import { EvaluationStarsChart } from "./widgets/EvaluationStarsChart";
import ExpandableWidget from "./widgets/ExpandableWidget";
import { useSubtypeList } from "../../store/selectors/actionsSelectors";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { CoinsIcon, FamilyStarIcon, SignatureIcon } from "../../assets/icons/Icons";
import { useTranslation } from "react-i18next";
import { PersonIcon } from "../../assets/icons/ActionIcons";
import { UpcomingBirthdaysChart } from "./widgets/UpcomingBirthdaysChart";
import { UpcomingAnniversariesChart } from "./widgets/UpcomingAnniversariesChart";
import { theme } from "../../theme";

const DashboardContent = () => {
  const { t } = useTranslation();
  const subtypeList = useSubtypeList()

  return (
    <DashboardContentStyles>
      <Container vPosition="center" hPosition="space-between">
        {/* <DashboardSelectBar vPosition="flex-start">
          <BasicSelectLightUI width={"205px"} minWidth={"205px"}>
            <Select
              value={group}
              onChange={(e) => setGroup(e.target.value)}
              IconComponent={ExpandMoreIcon}
            >
              {groups}
            </Select>
          </BasicSelectLightUI>
          <BasicSelectLightUI width={"110px"} minWidth={"110px"}>
            <Select
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
              IconComponent={ExpandMoreIcon}
            >
              {periods}
            </Select>
          </BasicSelectLightUI>
          <CheckBoxContainer>
            <Typography>Period: </Typography>
            <BasicCheckboxStyles>
              <label htmlFor="period">
                {" "}
                <input type="checkbox" id="period" />
                <span></span>
              </label>
            </BasicCheckboxStyles>
          </CheckBoxContainer>
          <DateSelectStyles>
            <Typography>Start:</Typography>
            <BasicTimePicker>
              <DatePicker
                value={""}
                format="DD.MM.YYYY"
                slots={{
                  openPickerIcon: ExpandMoreIcon,
                }}
              />
            </BasicTimePicker>
          </DateSelectStyles>
          <DateSelectStyles>
            <Typography>End:</Typography>
            <BasicTimePicker>
              <DatePicker
                value={""}
                format="DD.MM.YYYY"
                slots={{
                  openPickerIcon: ExpandMoreIcon,
                }}
              />
            </BasicTimePicker>
          </DateSelectStyles>
        </DashboardSelectBar>
        <EditBtn handleOnClick={() => { console.log("TODO: editing") }} /> */}
      </Container>
      <DashboardGrid>
        <StatisticsWrapper>
          <GeneralInfoData />
        </StatisticsWrapper>
        <StatisticsWrapper>
          <ExpandableWidget
            widgetTitle={t("dashboardPage.averagePoints.title")}
            IconComponent={CoinsIcon}>
            <AveragePointsChart />
          </ExpandableWidget>
        </StatisticsWrapper>
        <StatisticsWrapper>
          <ExpandableWidget
            widgetTitle={t("dashboardPage.totalPoints.title")}
            IconComponent={CoinsIcon}>
            <TotalPointsChart />
          </ExpandableWidget>
        </StatisticsWrapper>
        <StatisticsWrapper>
          <ExpandableWidget
            widgetTitle={t("dashboardPage.actionModulesDone.title")}
            IconComponent={CoinsIcon}>
            <ActionModulesDoneChart />
          </ExpandableWidget>
        </StatisticsWrapper>
        {subtypeList.subTypeNames.includes("PresenceQuota") &&
          <StatisticsWrapper>
            <ExpandableWidget
              widgetTitle={t("dashboardPage.presenceQuota.title")}
              IconComponent={CoinsIcon}>
              <PresenceQuotaChart />
            </ExpandableWidget>
          </StatisticsWrapper>
        }
        {subtypeList.subTypeNames.includes("EvaluationStars") &&
          <StatisticsWrapper>
            <ExpandableWidget
              widgetTitle={t("dashboardPage.evaluationStars.title")}
              IconComponent={FamilyStarIcon}>
              <EvaluationStarsChart />
            </ExpandableWidget>
          </StatisticsWrapper>
        }
        {subtypeList.subTypeNames.includes("SignatureControl") &&
          <StatisticsWrapper>
            <ExpandableWidget
              widgetTitle={t("dashboardPage.controlSignature.title")}
              IconComponent={SignatureIcon}>
              <ControlSignatureChart />
            </ExpandableWidget>
          </StatisticsWrapper>
        }
        {subtypeList.subTypeNames.includes("BirthdayBonus") &&
          <StatisticsWrapper>
            <ExpandableWidget
              widgetTitle={t("dashboardPage.upcomingBirthdays.title")}
              IconComponent={(props) => <PersonIcon {...props} colorHex={theme.palette.companyMain.main} />}>
              <UpcomingBirthdaysChart />
            </ExpandableWidget>
          </StatisticsWrapper>
        }
        {subtypeList.subTypeNames.includes("AnniversaryBonus") &&
          <StatisticsWrapper>
            <ExpandableWidget
              widgetTitle={t("dashboardPage.upcomingAnniversaries.title")}
              IconComponent={(props) => <PersonIcon {...props} colorHex={theme.palette.companyMain.main} />}>
              <UpcomingAnniversariesChart />
            </ExpandableWidget>
          </StatisticsWrapper>
        }

        {/* <StatisticsWrapper>
          <MidStatisticsContainer>
            <StatisticHeader
              icon={<CoinsIcon color={theme.palette.companyMain.main} />}
              title="Tax-Free benefit"
              children={
                <StatisticInfo
                  value={400}
                  subtitle="points"
                  icon={<CrossedCircleIcon />}
                />
              }
            />
          </MidStatisticsContainer>
          <StatisticCardStyles>
            <StatisticHeader
              icon={<CoinsIcon color={theme.palette.companyMain.main} />}
              title="Vouchers distributed"
              children={<StatisticInfo value={10} />}
            />
          </StatisticCardStyles>
        </StatisticsWrapper> */}
      </DashboardGrid>
    </DashboardContentStyles>
  );
};

export default DashboardContent;
