import PageTitle from "../../components/common/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import BillsTable from "./BillsTable";
import { SearchWithAutocompleteWrapper } from "../../components/common/SearchWithAutocomplete/SearchWithAutocomplete.styles";
import { Container } from "../../components/common/Common.styles";
import { BillingContentWrapper } from "./BillingPage.styles";
import { CircularIndeterminate } from "../../components/common/CircularIndeterminate";
import { useProcessingRequest } from "../../store/selectors/tableManagementSelector";
import { useFeatureFlags } from "../../store/selectors/featureFlagsSelectors";
import SectionNotAvailable from "../../scaffold/ui/SectionNotAvailable";
import ServerSideSearchBar from "../../components/common/table/ServerSideSearchBar";
import {companyBillingsApi} from "../../api/companyBillingsApi";
import {useServerSidePagination} from "../../hooks/PaginationHooks";

const BillingPage = () => {
  const { t } = useTranslation()
  const featureFlags = useFeatureFlags()
  const processingRequest = useProcessingRequest()

  const paginatedData = useServerSidePagination(
    async (paginationParameters) => {
      const res = await companyBillingsApi.getCompanyBillings(
        paginationParameters
      );
      return res?.result;
    }
  );

  return (
    <>
      <PageTitle breadcrumbs={[]} title={t("billingPage.title")} />
      {
        !featureFlags.includes("BillingPage") || featureFlags.includes("TestCompany")
          ?
          <SectionNotAvailable />
          :
          <BillingContentWrapper>
            <SearchWithAutocompleteWrapper>
              <Container>
                <ServerSideSearchBar onSearchHandler={paginatedData.handleOnSearch}/>
              </Container>
            </SearchWithAutocompleteWrapper>
            <BillsTable data={paginatedData}/>
          </BillingContentWrapper>
      }
      <CircularIndeterminate processingRequest={processingRequest} />
    </>
  );
};

export default BillingPage;
