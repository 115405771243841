import { NotificationLayout, NotificationsContainer } from './Notification.styles'
import { Box, Divider, Typography } from '@mui/material'
import SingleNotification from './SingleNotification';
import NotificationHeader from './NotificationHeader';
import { useAppDispatch } from '../../../../hooks/StoreHooks';
import { useNotificationList } from '../../../../store/selectors/notificationSelector';
import { useEffect, useState } from 'react';
import { fetchNotificationListThunk } from '../../../../store/thunk/notificationThunk';
import NotificationPagination from './NotificationPagination';

const NotificationContent = () => {

  const dispatch = useAppDispatch();
  const notificationList = useNotificationList();

  const [headerMenuSelected, setHeaderMenuSelected] = useState(0)
  const [paginationSelected, setPaginationSelected] = useState(1)

  const offsetValue = 10 * (paginationSelected - 1)

  useEffect(() => {
    dispatch(fetchNotificationListThunk({ offset: offsetValue, limit: 10 }));
  }, []);

  const handleHeaderMenu = (selected: number) => {
    setHeaderMenuSelected(selected);
  }

  const handlePagination = (selected: number) => {
    setPaginationSelected(selected);
  }

  const notificationsToRender = notificationList
    ? notificationList.items.map((note) => (
      !(headerMenuSelected === 0 && note.status === "Read") &&
      <SingleNotification key={note.id} notificationData={note} />
    ))
    : [];

  return (
    <NotificationLayout>
      <Box>
        <Typography color="text.secondary" variant="body2">
          <NotificationHeader headerMenuSelected={headerMenuSelected} setHeaderMenu={handleHeaderMenu} />
        </Typography>
      </Box>
      <Divider />
      <NotificationsContainer>
        <Typography color="text.secondary" variant="body2">
          {notificationsToRender}
        </Typography>
      </NotificationsContainer>
      <Box>
        <NotificationPagination paginationSelected={paginationSelected} setPagination={handlePagination} />
      </Box>
    </NotificationLayout>
  )
}

export default NotificationContent