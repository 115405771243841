import { Box, styled } from "@mui/material";

export const CompanyManagementMainContentStyles = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "3.97fr 1fr",
  gap: "48px",
  [theme.breakpoints.down(1440)]: {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    gap: "30px",
    padding: "0 15px",
  },
  [theme.breakpoints.down(600)]: {
    gridTemplateRows: "auto",
    gap: "20px",
  },
}));

export const SEPAInstructionsStyles = styled(Box)(({ theme }) => ({
  padding: "18px 33px",
  backgroundColor: theme.palette.backgroundColor.main,
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  marginTop: "32px",
  ".MuiTypography-root": {
    "&:first-child": {
      fontSize: "1.25rem",
      fontWeight: "600",
      marginBottom: "20px",
    },
    "&:last-child": {
      fontSize: "1.125rem",
      lineHeight: "1",
      a: {
        color: theme.palette.textMain.main,
        fontWeight: "600",
      },
      marginTop: "12px",
    },
  },
}));

export const CompanyManagementTableStyles = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  background: theme.palette.backgroundColor.main,
  width: "100%",
  minWidth: "0",
}));

export const CompanyInfoTableRowStyles = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "24px 33px",
  fontSize: "1.25rem",
  lineHeight: "1.5",
  borderBottom: `1px solid ${theme.palette.border.main}`,
  boxSizing: "border-box",
  "&:last-child": {
    border: "none",
  },
  [theme.breakpoints.down(1600)]: {
    padding: "18px 24px",
    fontSize: "1.125rem",
    lineHeight: "1.4",
  },
  ".MuiTypography-root": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  ".MuiBox-root": {
    "&:first-child": {
      width: "209px",
      paddingRight: "10px",
      boxSizing: "border-box",
    },
    "&:last-child": {
      minWidth: "0",
      maxWidth: "calc(100% - 209px)",
      ".MuiTypography-root": {
        fontWeight: "600",
        maxWidth: "100%",
      },
    },
  },
}));

export const CompanyTableTitle = styled(CompanyInfoTableRowStyles)({
  fontWeight: "600",
});

export const EmptyRow = styled(CompanyInfoTableRowStyles)({
  minHeight: "72px",
});

export const InvalidBillingAdressRow = styled(Box)(({ theme }) => ({
  padding: "20px 26px",
  fontSize: "1.125rem",
  ".MuiTypography-root": {
    "&:first-child": { lineHeight: "1" },
    "&:last-child": {
      lineHeight: "1",
      a: { color: theme.palette.textMain.main },
    },
  },
}));
