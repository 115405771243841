import { ActionModuleManagementStyles, SearchBarWrapper } from "../ActionModuleManagement.styles";
import { actionsApi } from "../../../api/actionsApi";
import IncentivationOverviewTable from "./IncentivationOverviewTable";
import ServerSideSearchBar from "../../../components/common/table/ServerSideSearchBar";
import {useServerSidePagination} from "../../../hooks/PaginationHooks";

interface Props {
    actionModuleSubtype: string;
}

export function ActionModuleIncentivationOverview(props: Props) {
    const paginatedData = useServerSidePagination(
      async (paginationParameters) => {
        const res = await actionsApi.getEmployeesForSubtype(
          props.actionModuleSubtype,
          paginationParameters
        );
        return res.result;
      }
    )

    return <ActionModuleManagementStyles>
        <SearchBarWrapper>
            <ServerSideSearchBar onSearchHandler={paginatedData.handleOnSearch} />
        </SearchBarWrapper>
        <IncentivationOverviewTable paginatedData={paginatedData}
        />
    </ActionModuleManagementStyles>
}