import { CSSTransition } from 'react-transition-group';
import ActionCard from "./ActionCard/ActionCard";
import { useActionsData } from "../../../store/selectors/actionsSelectors";
import { useTableDataToRender } from "../../../hooks/UseTableDataToRender";
import {
  ActionEditComponent,
  ActionsBlockWrapper,
  ActionsGrid,
} from "../ActionsBlock.styles";
import { useEffect, useState } from "react";
import { ActionItem } from "../../../types/actionItem";
import { ActionEdit } from "./ActionEdit";
import { Box, styled } from "@mui/material";
import { CloseButton } from '../../../components/common/stylings/GlobalStyles';

const ActionCards = (props: { searchQuery: string }) => {
  const actionsData = useActionsData();
  const [isExiting, setIsExiting] = useState(false);
  const [clickedActionCard, setClickedActionCard] = useState<ActionItem | undefined>(undefined)
  const tableDataToRender = useTableDataToRender(
    props.searchQuery,
    actionsData,
    "name"
  );

  const hideEditComponent = () => {
    setIsExiting(true);
  }

  const handleCardClick = (action: ActionItem) => {
    if (clickedActionCard && clickedActionCard.id === action.id) {
      setIsExiting(true);
    } else {
      setClickedActionCard(action);
    }
  };

  useEffect(() => {
    if (isExiting) {
      const timer = setTimeout(() => {
        setClickedActionCard(undefined);
        setIsExiting(false);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isExiting]);

  const actionCards = tableDataToRender
    ? tableDataToRender.map((action: ActionItem) => (
      <ActionCard
        actionItem={action}
        key={action.id}
        onEditClicked={() => handleCardClick(action)}
      />
    ))
    : [];

  return (
    <ActionsBlockWrapper item={clickedActionCard ? true : false}>
      <ActionsGrid>
        {actionCards}
      </ActionsGrid>
      <CSSTransition
        in={!!clickedActionCard && !isExiting}
        timeout={500}
        classNames="slide"
        unmountOnExit
        onExited={() => setIsExiting(false)}
      >
        <ActionEditComponent className={isExiting ? 'exiting' : ''}>
          <CloseButton onClick={hideEditComponent}>X</CloseButton>
          {clickedActionCard && <ActionEdit actionItem={clickedActionCard} />}
        </ActionEditComponent>
      </CSSTransition>
    </ActionsBlockWrapper>
  );
};

export default ActionCards;
