import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ActionItem, ActionModuleInstancesEditItem, BenefitItem, SubtypeList } from "../../types/actionItem";
import { fetchActions, fetchBenefitList, fetchSubtypes } from "../thunk/actionsThunk";


export interface ActionsInitialState {
  actionsData: ActionItem[];
  actionToEdit: ActionItem | Partial<ActionItem> | null;
  actionInstancesToEdit: ActionModuleInstancesEditItem[] | Partial<ActionModuleInstancesEditItem>[] | []
  subtypeList: SubtypeList
  benefitList: BenefitItem[]
  editMode: boolean
}

const initialState: ActionsInitialState = {
  actionsData: [],
  actionToEdit: null,
  actionInstancesToEdit: [],
  benefitList: [],
  subtypeList: {
    subTypeNames: []
  },
  editMode: false
};

const actionsSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    setActions: () => { },
    setActionData: (state, action: PayloadAction<Partial<ActionItem>>) => {
      if (state.actionToEdit)
        state.actionToEdit = { ...state.actionToEdit, ...action.payload };
      else state.actionToEdit = action.payload;
    },
    setActionEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    setEditedActionData: (state, action: PayloadAction<Partial<ActionItem>>) => {
      state.actionToEdit = action.payload
    },
    setEditedActionInstancesData: (state, action: PayloadAction<Partial<ActionModuleInstancesEditItem>>) => {
      state.actionInstancesToEdit = state.actionInstancesToEdit.length === 0
        ? [action.payload]
        : [
          ...state.actionInstancesToEdit.filter(
            (item) => item.actionModuleInstanceId !== action.payload.actionModuleInstanceId
          ),
          action.payload,
        ];
    },
    setSubtypeList: (state, action: PayloadAction<SubtypeList>) => {
      state.subtypeList = action.payload;
    },
    deleteAction: (state, action: PayloadAction<number>) => {
      state.actionsData = state.actionsData.filter(
        (item) => item.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActions.fulfilled, (state, action) => {
      if (!action.payload || !(action.payload as ActionItem[]).length) {
        state.actionsData = []
      } else {
        state.actionsData = action.payload as ActionItem[];
      }
    });
    builder.addCase(fetchBenefitList.fulfilled, (state, action) => {
      if (!action.payload || !(action.payload as BenefitItem[]).length) {
        state.benefitList = []
      } else {
        state.benefitList = action.payload as BenefitItem[];
      }
    });
    builder.addCase(fetchSubtypes.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.subtypeList = action.payload as SubtypeList
        }
      });
  },
});

export const {
  deleteAction,
  setActions,
  setActionData,
  setActionEditMode,
  setEditedActionData,
  setEditedActionInstancesData
} = actionsSlice.actions;

export default actionsSlice.reducer;
