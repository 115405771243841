import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const copySuccessVisibleStyle = {
  background: "#43B8BB",
  padding: "4px 10px",
  borderRadius: "10px",
  position: 'absolute' as 'absolute',
  bottom: "100%",
  left: "20%",
  color: "white"
}

export const CloseButton = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: "0",
  right: "0",
  width: "30px",
  height: "30px",
  margin: "10px",
  backgroundColor: "lightgrey",
  color: "#fff",
  fontWeight: "bold",
  borderRadius: "50%",
  fontSize: "16px",
  cursor: "pointer",
  transition: "0.3s",
  "&:hover": {
    backgroundColor: "lightgrey",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
  },
}));