import {useTranslation} from "react-i18next";
import BorderRow from "../../../../components/common/table/BorderRow";
import {TableHeaderWrapper} from "../../../../components/common/Common.styles";
import ServerSideTableColumnHeader from "../../../../components/common/table/ServerSideTableColumnHeader";
import {PaginatedData} from "../../../../hooks/PaginationHooks";

interface Props {
  paginatedData: PaginatedData<any>;
}

export default function TaxFreeBenefitTableHeader(props: Props) {
  const {t} = useTranslation();

  return (
    <TableHeaderWrapper>
      <ServerSideTableColumnHeader
        fieldName="name"
        isSorted={true}
        label={t("tableColumnHeaders.name")}
        firstCol={true}
        paginatedData={props.paginatedData}
      />
      <ServerSideTableColumnHeader
        fieldName="position"
        isSorted={true}
        label={t("tableColumnHeaders.position")}
        paginatedData={props.paginatedData}
      />
      <ServerSideTableColumnHeader
        fieldName="employeeNumber"
        isSorted={true}
        label={t("tableColumnHeaders.employeeNo")}
        paginatedData={props.paginatedData}
      />
      <ServerSideTableColumnHeader
        fieldName="group"
        isSorted={true}
        label={t("tableColumnHeaders.group")}
        paginatedData={props.paginatedData}
      />
      <ServerSideTableColumnHeader
        fieldName="status"
        isSorted={false}
        lastCol={true}
        label={t("tableColumnHeaders.statusAndPoints")}
      />
      <BorderRow/>
    </TableHeaderWrapper>
  );
};
