import { useEffect, useState } from "react";
import { useActivatedAccountsData } from "../../../../store/selectors/reportingSelectors";
import { fetchActivatedAccountsThunk } from "../../../../store/thunk/reportingThunk";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../hooks/StoreHooks";
import { AccountCounter, ActivatedAccountsBox, ActivatedAccountsText, ActiveUsersText, TotalUsersText } from "../../Dashboard.styles";
import { PersonIcon } from "../../../../assets/icons/ActionIcons";

const ActivatedAccountsWidget = () => {
    const [activeUsers, setActiveUsers] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const { t } = useTranslation();
    const activatedAccountsData = useActivatedAccountsData();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchActivatedAccountsThunk());
    }, [dispatch]);

    useEffect(() => {
        if (activatedAccountsData[0]) {
            setActiveUsers(activatedAccountsData[0].active);
            setTotalUsers(activatedAccountsData[0].total);
        }
    }, [activatedAccountsData]);

    return (
        <ActivatedAccountsBox sx={{ width: "33%" }}>
            <PersonIcon />
            <ActivatedAccountsText>{t("dashboardPage.activatedAccounts")}</ActivatedAccountsText>
            <AccountCounter>
                <ActiveUsersText>{activeUsers} </ActiveUsersText>
                <TotalUsersText color="grey"> / {totalUsers}</TotalUsersText>
            </AccountCounter>
        </ActivatedAccountsBox>
    )
}

export default ActivatedAccountsWidget