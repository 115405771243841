import {
  Accordion,
  AccordionDetails,
  Avatar,
  Box,
  Drawer,
  MenuList,
  Popover,
  Stack,
  styled,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { theme } from "../../theme";

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

export const TopNavStyles = styled(Box)({
  backdropFilter: "blur(6px)",
  backgroundColor: alpha(theme.palette.background.default, 0.8),
  position: "sticky",
  left: `${SIDE_NAV_WIDTH}px`,
  top: 0,
  width: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
  zIndex: theme.zIndex.appBar,
  [theme.breakpoints.down("lg")]: {
    left: 0,
    width: "100%",
  },
});

export const NavStackStyles = styled(Stack)({
  minHeight: TOP_NAV_HEIGHT,
  padding: "0 32px",
});

export const AvatarStyles = styled(Avatar)({
  cursor: "pointer",
  height: "40px",
  width: "40px",
  "& > .MuiBox-root": {
    display: "flex",
  },
});

export const SideNavWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  backgroundColor: "#323232",
  overflowY: "auto"
});

export const LogoWrapper = styled(Box)({
  padding: "32px",
  "& > .MuiBox-root": {
    display: "inline-flex",
    width: "100%",
  },
});

export const NavBox = styled(Box)({
  flexGrow: "1",
  padding: "16px",
  overflowY: "auto",
  "& > .MuiStack-root": {
    listStyle: "none",
    padding: "0",
    margin: "0",
  },
});

export const NavDrawerStyles = styled(Drawer)({
  "& > .MuiPaper-root": {
    width: "280px",
  },
  [theme.breakpoints.down("lg")]: {
    zIndex: theme.zIndex.appBar + 100,
  },
});

export const LinkWrapper = ({
  children,
  isActive,
  onClick
}: {
  children: React.ReactNode;
  isActive: boolean;
  onClick: () => void
}) => {
  return (
    <Box
      sx={(theme) => ({
        alignItems: "center",
        borderRadius: 1,
        display: "flex",
        justifyContent: "flex-start",
        pl: "16px",
        pr: "16px",
        py: "6px",
        textAlign: "left",
        width: "100%",
        boxSizing: "border-box",
        cursor: "pointer",
        svg: {
          width: "20px",
          height: "20px",
          path: {
            fill: theme.palette.backgroundColor.main + "!important",
          },
        },
        ...(isActive && {
          backgroundColor: "rgba(255, 255, 255, 0.04)",
          color: theme.palette.primary.main,
          svg: {
            width: "20px",
            height: "20px",
            transition: "all 0.3s ease",
            path: {
              fill: theme.palette.primary.main + "!important",
            },
          },
        }),
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.04)",
        },
        div: {
          color: (theme) => theme.palette.backgroundColor.main,
          fontFamily: (theme) => theme.typography.fontFamily,
          fontSize: 14,
          fontWeight: 600,
          lineHeight: "24px",
          whiteSpace: "nowrap",
          textDecoration: "none",
          ml: "6px",
          ...(isActive && {
            color: (theme) => theme.palette.companyMain.main,
          }),
        },
      })}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export const LayoutWithNavBarStyles = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  background: theme.palette.backgroundColor.secondary,
}));

export const LayoutBox = styled(Box)({
  margin: "40px",
});

export const AccountPopoverStyles = styled(Popover)({
  "& > .MuiPaper-root": {
    width: "200px",
    "& > .MuiBox-root": {
      padding: "12px 16px",
    },
  },
});

export const MenuListStyles = styled(MenuList)({
  padding: "8px",
  "& > *": {
    borderRadius: "1",
  },
});

export const ComplexSideBarLinkUI = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <Box
      sx={{
        ".MuiPaper-root": {
          background: "transparent",
          boxShadow: "none",
        },
        ".MuiButtonBase-root": {
          minHeight: "0!important",
        },
        ".MuiAccordionSummary-content": {
          margin: "0!important",
          "& .MuiBox-root": {
            svg: {
              width: "20px",
              height: "20px",
              marginRight: "6px",
            },
            ".MuiTypography-root": {
              fontWeight: "600",
              fontSize: "0.9rem",
              color: (theme) => theme.palette.backgroundColor.main,
            },
          },
        },
        ".MuiAccordionSummary-expandIconWrapper": {
          svg: {
            width: "16px",
            height: "16px",
            path: {
              fill: (theme) => theme.palette.backgroundColor.main,
            },
          },
        },
        ".MuiAccordionDetails-root": {
          padding: "8px",
        },
      }}
    >
      {children}
    </Box>
  );
};

export const GroupsComplexLinkBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "6px 0",
});

export const GroupsComplexLinkAccordionDetails = styled(AccordionDetails)({
  paddingLeft: "24px!important",
});

export const IncentivationComplexLinkBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "6px 0",
  svg: { path: { fill: "#fff!important" } },
});

export const AccordionDetailsMain = styled(AccordionDetails)({
  paddingRight: "0!important",
});

export const AccordionMain = styled(Accordion)(
  ({ expanded }: { expanded: boolean }) => ({
    ".MuiAccordionSummary-expandIconWrapper": {
      svg: {
        path: {
          fill: expanded ? theme.palette.companyMain.main : "",
        },
      },
    },
    ".MuiAccordionDetails-root": {
      paddingRight: "0!important",
      paddingLeft: "16px!important",
    },
  })
);

export const AccordionSummaryBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  ".MuiBox-root": {
    background: "none!important",
  },
  a: { marginLeft: "0!important" },
});
