import { useTranslation } from "react-i18next";
import BorderRow from "../../../components/common/table/BorderRow";
import { TableHeaderWrapper } from "../../../components/common/Common.styles";
import ServerSideTableColumnHeader from "../../../components/common/table/ServerSideTableColumnHeader";
import {PaginatedData} from "../../../hooks/PaginationHooks";

interface AccountManagementTableHeaderProps {
  paginatedData: PaginatedData<any>
}

const AccountManagementTableHeader = (props: AccountManagementTableHeaderProps) => {
  const { t } = useTranslation();
  
  return (
    <TableHeaderWrapper>
      <ServerSideTableColumnHeader
        fieldName="firstName"
        isSorted={true}
        paginatedData={props.paginatedData}
        label={t("tableColumnHeaders.firstName")}
        firstCol={true}
      />
      <ServerSideTableColumnHeader
        fieldName="lastName"
        isSorted={true}
        paginatedData={props.paginatedData}
        label={t("tableColumnHeaders.lastName")}
      />
      <ServerSideTableColumnHeader
        fieldName="birthDate"
        isSorted={true}
        paginatedData={props.paginatedData}
        label={t("tableColumnHeaders.birthDate")}
      />
      <ServerSideTableColumnHeader
        fieldName="employmentDate"
        isSorted={true}
        paginatedData={props.paginatedData}
        label={t("tableColumnHeaders.employmentDate")}
      />
      <ServerSideTableColumnHeader
        fieldName="position"
        isSorted={true}
        paginatedData={props.paginatedData}
        label={t("tableColumnHeaders.position")}
      />
      <ServerSideTableColumnHeader
        fieldName="employeeNumber"
        isSorted={true}
        paginatedData={props.paginatedData}
        label={t("tableColumnHeaders.employeeNo")}
      />
      <ServerSideTableColumnHeader
        fieldName="group"
        isSorted={true}
        paginatedData={props.paginatedData}
        label={t("tableColumnHeaders.group")}
      />
      <ServerSideTableColumnHeader
        fieldName="status"
        isSorted={false}
        label={t("tableColumnHeaders.status")}
        paginatedData={props.paginatedData}
      />
      <ServerSideTableColumnHeader
        fieldName="registrationCode"
        isSorted={false}
        lastCol={true}
        label={t("tableColumnHeaders.invitationCode")}
        paginatedData={props.paginatedData}
      />
      <BorderRow />
    </TableHeaderWrapper>
  );
};

export default AccountManagementTableHeader;
