import { useTranslation } from "react-i18next";
import BorderRow from "../../../../components/common/table/BorderRow";
import { TableHeaderWrapper } from "../../../../components/common/Common.styles";
import ServerSideTableColumnHeader from "../../../../components/common/table/ServerSideTableColumnHeader";
import {PaginatedData} from "../../../../hooks/PaginationHooks";

interface Props {
  paginatedData: PaginatedData<any>;
}

export default function GroupsTableHeader(props: Props) {
  const { t } = useTranslation();

  return (
    <TableHeaderWrapper>
      <ServerSideTableColumnHeader
        fieldName="name"
        isSorted={true}
        label={t("tableColumnHeaders.group")}
        paginatedData={props.paginatedData}
        firstCol={true}
      />
      <ServerSideTableColumnHeader
        isSorted={false}
        label={t("tableColumnHeaders.actionModules")}
      />
      <ServerSideTableColumnHeader
        isSorted={false}
        label={t("tableColumnHeaders.maxPoints")}
      />
      <ServerSideTableColumnHeader
        isSorted={false}
        label={t("tableColumnHeaders.staff")}
      />
      <ServerSideTableColumnHeader
        isSorted={false}
        label={t("tableColumnHeaders.status")}
      />
      <BorderRow />
    </TableHeaderWrapper>
  );
};
