export enum FulfilledStatusType {
  NotFulfilled = "NotFulfilled",
  Partially = "PartiallyFulfilled",
  Fulfilled = "FullyFulfilled",
}

export enum BenefitType {
  Point = "Point",
  Coin = "Coin",
  Flame = "Flame",
}

export interface BenefitItem {
  id: number;
  name: string;
  benefitType: string;
}

export interface ActionItem {
  name: string;
  description: string;
  companyId: number;
  iconName: string;
  colorHex: string;
  frequency: string;
  recurring: string;
  id: number;
  maxPoints: number;
  type: string;
  subtype: string;
  lastUploadDate: string | null;
  employeeDescription: string;
  executionTimesPerMonth: number;
  benefitType: BenefitType;
  excessPointsToCoins: boolean;
  rewardedPointsFullyFullfilled: number;
  rewardedPointsNotFullfilled: number;
  rewardedPointsPartiallyFullfilled: number;
  scoreRanges: ScoreRange[];
  maxUserCanComplete: number | null;
}

export interface ActionModuleInstancesEditItem {
  actionModuleId: string;
  employeeId: string;
  actionModuleInstanceId: number;
  fulfilledStatus: string
}

export type ActionItemIcon = {
  colorHex?: string;
};

export type ActionIconData = {
  iconName: string;
  colorHex: string;
};

export interface ScoreRange {
  scoreFrom: number;
  scoreTo: number;
  pointsRewarded: number;
};

export interface SubtypeList {
  subTypeNames: string[]
}
