import CertificatesTableHeader from "./CertificatesTableHeader";
import {MainContentWrapper, TableWrapper} from "../../../components/common/Common.styles";
import {
  useOnNewCertificateAdd,
} from "../../../store/selectors/certificatesSelectors";
import {CertificateItem} from "../../../types/certificates";
import CertificatesTableRow from "./CertificatesTableRow";
import CertificatesCreateOrEdit from "./CertificatesCreateOrEdit";
import {Box} from "@mui/material";
import {ServerSidePagination} from "../../../components/common/table/ServerSidePagination";
import {PaginatedData} from "../../../hooks/PaginationHooks";

interface Props {
  paginatedData: PaginatedData<CertificateItem>;
}

export default function CertificatesTable(props: Props) {
  const newUserMode = useOnNewCertificateAdd();

  return (
    <MainContentWrapper>
      <TableWrapper
        minWidth="1330px"
        columnsTemplate="1.25fr 1fr 2fr 1.5fr 1.5fr 1fr 1fr"
      >
        <CertificatesTableHeader paginatedData={props.paginatedData}/>

        <Box sx={{display: "contents"}}>
          {newUserMode && <CertificatesCreateOrEdit isNewCertificateAdd={newUserMode}/>}
          {props.paginatedData.items.map((item) => (<CertificatesTableRow
            key={item.id}
            certificateData={item}/>))}
        </Box>
      </TableWrapper>


      <ServerSidePagination paginatedData={props.paginatedData}/>
    </MainContentWrapper>
  );
};
