import GroupsTableHeader from "./GroupsTableHeader";
import {
  TableWrapper,
} from "../../../../components/common/Common.styles";
import {Box} from "@mui/material";
import AddOrEditGroupForm from "./GroupsTableRow/GroupFormComponents/AddOrEditGroupForm";
import GroupsTableRow from "./GroupsTableRow/GroupsTableRow";
import {useOnNewGroupAdd} from "../../../../store/selectors/groupSelectors";
import {PaginatedData} from "../../../../hooks/PaginationHooks";

interface Props {
  paginatedData: PaginatedData<any>;
}

export default function GroupsTable(props: Props) {
  const isNewGroupAdd = useOnNewGroupAdd();

  return (
    <TableWrapper
      minWidth="1230px"
      columnsTemplate="2.57fr 3.05fr 1.17fr 1fr 1fr 175px"
    >
      <GroupsTableHeader paginatedData={props.paginatedData}/>

      <Box sx={{ display: "contents" }}>
        {isNewGroupAdd && <AddOrEditGroupForm isNewGroupAdd={isNewGroupAdd} />}
        {
          props.paginatedData.items.map((item) => <GroupsTableRow key={item.id} {...item} />)
        }
      </Box>
    </TableWrapper>
  );
};
