import { useTranslation } from "react-i18next"
import { Box } from '@mui/material'
import { useUpcomingAnniversariesData } from '../../../store/selectors/reportingSelectors'
import { UpcomingAnniversariesDataWrapper } from '../Dashboard.styles'
import { fetchUpcomingAnniversariesThunk } from "../../../store/thunk/reportingThunk"
import { useEffect } from "react"
import { useAppDispatch } from "../../../hooks/StoreHooks"

export const UpcomingAnniversariesChart = () => {
  const { t } = useTranslation();
  const upcomingAnniversariesData = useUpcomingAnniversariesData();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUpcomingAnniversariesThunk());
  }, [dispatch]);

  return (
    <div>
      {upcomingAnniversariesData ?
        <UpcomingAnniversariesDataWrapper>
          <Box>{t("dashboardPage.upcomingAnniversaries.numberAnniversariesInCurrentMonth")}: {upcomingAnniversariesData.numberAnniversariesInCurrentMonth}</Box>
          <Box>{t("dashboardPage.upcomingAnniversaries.numberAnniversariesInNextMonth")}: {upcomingAnniversariesData.numberAnniversariesInNextMonth}</Box>
        </UpcomingAnniversariesDataWrapper> :
        <UpcomingAnniversariesDataWrapper>
          <Box>{t("dashboardPage.noDataAvailable")}</Box>
        </UpcomingAnniversariesDataWrapper>
      } 
    </div>
  )
}