import { createAsyncThunk } from "@reduxjs/toolkit";
import { groupsApi } from "../../api/groupsApi";
import { GroupItem } from "../../types/groupItem";
import { createGroup, setEditMode } from "../reducers/groupsReducer";
import { RootState } from "../store";
import { setProcessingRequest } from "../reducers/tableManagementReducer";

export const fetchGroupsThunk = createAsyncThunk(
  "groups/fetchGroups",
  async (_, thunkApi) => {
    try {
      const response = await groupsApi.getAllGroups();
      thunkApi.dispatch(setProcessingRequest(false))
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const editGroupsThunk = createAsyncThunk(
  "groups/editGroup",
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const groupsDataToEdit = state.groups.editedGroupsData;
    const groupsDataToEditRequests = groupsDataToEdit.map((group) =>
      groupsApi.editGroup(group.id, {
        name: group.name,
        actionModuleIds: group.actionModuleIds,
        maxPoints: group.maxPoints,
        status: group.status,
      })
    );

    await Promise.all(groupsDataToEditRequests)
      .then(async () => {
        await thunkApi.dispatch(fetchGroupsThunk());
        thunkApi.dispatch(setEditMode(false));
        thunkApi.dispatch(setProcessingRequest(false))
        return groupsDataToEdit;
      })
      .catch(error => console.log(error))
    return state.groups.editedGroupsData;
  }
);

export const createGroupThunk = createAsyncThunk(
  "groups/createGroup",
  async (groupData: GroupItem, thunkApi) => {
    const { name, actionModuleIds, type, status, maxPoints } = groupData;
    const newGroupData = {
      name,
      actionModuleIds,
      type,
      status,
      maxPoints,
    };

    thunkApi.dispatch(createGroup(groupData));
    groupsApi.createGroup(newGroupData)
      .then(async () => {
        await thunkApi.dispatch(fetchGroupsThunk())
        thunkApi.dispatch(setProcessingRequest(false))
      })
      .catch(error => console.log(error))
  }
);

export const deleteGroupThunk = createAsyncThunk(
  "groups/deleteGroup",
  (id: number, thunkApi) => {
    groupsApi.deleteGroup(id)
      .then(async () => {
        await thunkApi.dispatch(fetchGroupsThunk())
        thunkApi.dispatch(setEditMode(false))
        thunkApi.dispatch(setProcessingRequest(false))
      })
      .catch(error => console.log(error))
    return id
  }
);
