import { GeneralInfoContainer } from "../Dashboard.styles";
import ActivatedAccountsWidget from "./GeneralInfo/ActivatedAccountsWidget";

const GeneralInfoData = () => {
  return (
    <GeneralInfoContainer>
      <ActivatedAccountsWidget />
    </GeneralInfoContainer>
  )
}

export default GeneralInfoData