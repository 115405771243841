import { useAppDispatch } from "../../hooks/StoreHooks";
import {
  useAccountManagementEditMode
} from "../../store/selectors/accountManagementSelectors";
import { setAccountManagementEditMode, setNewUserMode } from "../../store/reducers/accountManagementReducer";
import SaveBtn from "../../components/common/table/SaveBtn";
import EditBtn from "../../components/common/table/EditBtn";
import { FC } from "react";
import { editAccountManagementUsersThunk } from "../../store/thunk/accountManagementThunk";
import { SearchWithAutocompleteWrapper } from "../../components/common/SearchWithAutocomplete/SearchWithAutocomplete.styles";
import AddIcon from '@mui/icons-material/Add';
import FileUploaderBox from "./AccountManagementTable/FileUploaderBox";
import { SearchBarButtonContainer } from "./AccountManagement.styles";
import { t } from "i18next";
import { setProcessingRequest, setResponseMessage, setResponseValue } from "../../store/reducers/tableManagementReducer";
import BulkDownloadOnboardingFilesButton from "./BulkDownloadOnboardingFilesButton";
import { IconButton, Tooltip } from "@mui/material";
import { theme } from "../../theme";
import ServerSideSearchBar from "../../components/common/table/ServerSideSearchBar";

interface AccountManagementSearchBarProps {
  handleOnSearch: (searchQuery: string) => void;
}

interface NewUserBtnProps {
  handleOnClick: () => void;
}

const NewUserBtn: FC<NewUserBtnProps> = (props) => {
  const editMode = useAccountManagementEditMode();
  if (editMode) return null;
  return (
    <Tooltip title={t("label.addNewUser")}>
      <IconButton onClick={props.handleOnClick}>
        <AddIcon sx={{ color: theme.palette.companyMain.main }} />
      </IconButton>
    </Tooltip>
  )
};

const AccountManagementSearchBar: FC<AccountManagementSearchBarProps> = ({
  handleOnSearch: onSearchHandler,
}) => {
  const dispatch = useAppDispatch();
  const editMode = useAccountManagementEditMode();

  const handleOnSave = async () => {
    dispatch(setProcessingRequest(true))
    let res = await dispatch(editAccountManagementUsersThunk())
    dispatch(setResponseValue(res.type))
    dispatch(setResponseMessage(`${t("dialogModals.itemsSaved")}`))
  }

  return (
    <SearchWithAutocompleteWrapper>
      <ServerSideSearchBar onSearchHandler={onSearchHandler} />
      <SearchBarButtonContainer>
        <BulkDownloadOnboardingFilesButton />
        <NewUserBtn handleOnClick={() => dispatch(setNewUserMode(true))} />
        {!editMode
          ? <EditBtn handleOnClick={() => dispatch(setAccountManagementEditMode(true))} />
          : <SaveBtn handleOnClick={handleOnSave} />
        }
        <FileUploaderBox />
      </SearchBarButtonContainer>
    </SearchWithAutocompleteWrapper>
  );
};

export default AccountManagementSearchBar;
