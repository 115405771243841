import { doApiGet } from "./Requests";

export const reportingApi = {
    getAveragePoints: (month: number, take: number) =>
        doApiGet(`/api/companies/current/employees/reporting/averagepoints/${month}?take=${take}`)
            .then(res => res.result)
            .catch(error => console.log(error)),

    getTotalPoints: (take: number) =>
        doApiGet(`/api/companies/current/employees/reporting/totalpoints?take=${take}`)
            .then(res => res.result)
            .catch(error => console.log(error)),

    getActionModulesDone: (take: number) =>
        doApiGet(`/api/companies/current/employees/reporting/doneactionmodules?take=${take}`)
            .then(res => res.result)
            .catch(error => console.log(error)),

    getPresenceQuota: (year: number, month: number) =>
        doApiGet(`/api/companies/current/employees/reporting/presencequota/${year}/${month}`)
            .then(res => res.result)
            .catch(error => console.log(error)),

    getControlSignatureWeekly: (year: number, month: number) =>
        doApiGet(`/api/companies/current/employees/reporting/controlsignature/${year}/${month}/weekly`)
            .then(res => res.result)
            .catch(error => console.log(error)),

    getControlSignatureMonthly: (year: number, month: number) =>
        doApiGet(`/api/companies/current/employees/reporting/controlsignature/${year}/${month}/monthly`)
            .then(res => res.result)
            .catch(error => console.log(error)),

    getActivatedAccounts: () =>
        doApiGet(`/api/companies/current/employees/reporting/activatedaccounts`)
            .then(res => res.result)
            .catch(error => console.log(error)),

    getEvaluationStars: (startDate: string, endDate: string) =>
        doApiGet(`/api/companies/current/employees/reporting/evaluationStars?startDate=${startDate}&endDate=${endDate}`)
            .then(res => res.result)
            .catch(error => console.log(error)),

    getUpcomingAnniversaries: () =>
        doApiGet(`/api/companies/current/employees/reporting/upcomingAnniversaries`)
            .then(res => res.result)
            .catch(error => console.log(error)),

    getUpcomingBirthdays: () =>
        doApiGet(`/api/companies/current/employees/reporting/upcomingBirthdays`)
            .then(res => res.result)
            .catch(error => console.log(error)),
}