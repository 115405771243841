import { useAppSelector } from "../../hooks/StoreHooks";
import { ActionItem } from "../../types/actionItem";

export const useActionsData = () =>
  useAppSelector((state) => state.actions.actionsData);

export const useEditedActionData = () =>
  useAppSelector((state) => state.actions.actionToEdit);

export const useActionEditMode = () =>
  useAppSelector((state) => state.actions.editMode);

export const useSubtypeList = () =>
  useAppSelector((state) => state.actions.subtypeList);

export const useBenefitList = () => 
  useAppSelector((state) => state.actions.benefitList);

export const useEditedActionInstancesData = () =>
  useAppSelector((state) => state.actions.actionInstancesToEdit);

export const useSpecificAction = (id: number): ActionItem | undefined =>
  useAppSelector((state) =>
    state.actions.actionsData.find((action) => action.id === id)
  );

export const useActionToEditData = ():
  | ActionItem
  | Partial<ActionItem>
  | null => useAppSelector((state) => state.actions.actionToEdit);
