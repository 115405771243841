import { createAsyncThunk } from "@reduxjs/toolkit";
import { actionsApi } from "../../api/actionsApi";
import { RootState } from "../store";
import { ActionItem, SubtypeList } from "../../types/actionItem";

const actionDataValidation = (actionData: Partial<ActionItem>) => {
  const actionDateRequiredKeys = [
    "name",
    "description",
    "companyId",
    "iconName",
    "colorHex",
    "frequency",
    "recurring",
    "maxPoints",
    "rewardedPointsFullyFullfilled",
    "rewardedPointsPartiallyFullfilled",
  ];

  let isValid = true;
  actionDateRequiredKeys.forEach((key) => {
    if (!actionData[key as keyof Partial<ActionItem>]) {
      isValid = false;
      console.log("error:", key);
    }
  });
  return isValid;
};

export const fetchActions = createAsyncThunk(
  "actions/fetchActions",
  async () => {
    try {
      const response = await actionsApi.getActions();
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchBenefitList = createAsyncThunk(
  "actions/fetchBenefitList",
  async () => {
    try {
      const response = await actionsApi.getBenefitList();
      return response;
    } catch (error) {
      console.log(error);
    }
  }
)

export const fetchSubtypes = createAsyncThunk(
  "actions/fetchSubtypes",
  async () => {
    try {
      const response = await actionsApi.getSubtypes();
      return response as SubtypeList;
    } catch (error) {
      console.log(error);
    }
  }
);

export const createActionThunk = createAsyncThunk(
  "actions/createAction",
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const newActionData = state.actions.actionToEdit;
    const newAction: Partial<ActionItem> = {
      ...newActionData,
      frequency: "string",
      recurring: "string",
      companyId: 5,
    };

    const isValid = actionDataValidation(newAction);
    console.log(newAction, isValid);
  }
);

export const editActionThunk = createAsyncThunk(
  "action/editAction",
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const actionDataToEdit = state.actions.actionToEdit;
    if(actionDataToEdit && actionDataToEdit.id){
      await actionsApi.editAction(actionDataToEdit.id, actionDataToEdit)
      await thunkApi.dispatch(fetchActions());
    }
  }
);

export const deleteActionThunk = createAsyncThunk(
  "actions/deleteAction",
  (actionId: number, thunkApi) => {
    actionsApi.deleteAction(actionId)
      .then(() => thunkApi.dispatch(fetchActions()))
      .catch(error => console.log(error))
  }
);
