import { Box, Typography } from "@mui/material";
import { BasicTableRowStyles, TableHeaderWrapper } from "../../../components/common/Common.styles";
import { useTranslation } from "react-i18next";
import BorderRow from "../../../components/common/table/BorderRow";
import CellBox from "../../../components/common/table/CellBox";
import StatusDisplay from "../../../components/common/table/StatusDisplay";
import { ServerSidePagination } from "../../../components/common/table/ServerSidePagination";
import EmployeeIncentivationType from "../../../types/employeeIncentivationType";
import ServerSideTableColumnHeader from "../../../components/common/table/ServerSideTableColumnHeader";
import { TableLink } from "../../../components/common/TableLink";
import {PaginatedData} from "../../../hooks/PaginationHooks";

interface Props {
    paginatedData: PaginatedData<EmployeeIncentivationType>;
}

export default function IncentivationOverviewTable(props: Props) {
    return <Box>
        <IncentivationOverviewTableHeader paginatedData={props.paginatedData}/>
        <Box sx={{ display: "contents" }}>
            {
                props.paginatedData.items.map((u) =>
                    <IncentivationOverviewTableRow
                        key={u.id}
                        row={u}
                    />)
            }
        </Box>
        <ServerSidePagination paginatedData={props.paginatedData} />
    </Box >
}

interface IncentivationOverviewTableHeaderProps {
    paginatedData: PaginatedData<any>;
    extraColumn?: string
}

function IncentivationOverviewTableHeader(props: IncentivationOverviewTableHeaderProps) {
    const { t } = useTranslation();
    const gridTemplate = `3fr 2fr 2fr 2fr 1fr`
    return <Box gridTemplateColumns={gridTemplate} display="grid">
        <TableHeaderWrapper>
            <ServerSideTableColumnHeader
                fieldName="Name"
                isSorted={true}
                label={t("tableColumnHeaders.name")}
                paginatedData={props.paginatedData}
                firstCol={true}
            />
            <ServerSideTableColumnHeader
                fieldName="ActionModuleName"
                isSorted={false}
                label={t("tableColumnHeaders.actionModule")}
                firstCol={false}
            />
            <ServerSideTableColumnHeader
                fieldName="Position"
                isSorted={true}
                label={t("tableColumnHeaders.position")}
                paginatedData={props.paginatedData}
            />
            <ServerSideTableColumnHeader
                fieldName="EmployeeNumber"
                isSorted={true}
                label={t("tableColumnHeaders.employeeNo")}
                paginatedData={props.paginatedData}
            />
            <ServerSideTableColumnHeader
                fieldName="status"
                label={t("tableColumnHeaders.status")}
                lastCol={true}
            />
            <BorderRow />
        </TableHeaderWrapper>
    </Box>
}

interface IncentivationOverviewTableRowProps {
    row: EmployeeIncentivationType,
}

function IncentivationOverviewTableRow(props: IncentivationOverviewTableRowProps) {
    const gridTemplate = `3fr 2fr 2fr 2fr 1fr`
    return <>
        <Box gridTemplateColumns={gridTemplate} display="grid">
            <BasicTableRowStyles>
                <CellBox firstCol={true}>
                    <Typography>{props.row.name}</Typography>
                </CellBox>
                <TableLink to={`${props.row.actionModuleId}`}>
                    <CellBox contentPosition="left">
                        <Typography align="left">{props.row.actionModuleName}</Typography>
                    </CellBox>
                </TableLink>
                <CellBox>
                    <Typography>{props.row.position}</Typography>
                </CellBox>
                <CellBox>
                    <Typography>{props.row.employeeNumber}</Typography>
                </CellBox>
                <CellBox lastCol={true}>
                    <StatusDisplay status={props.row.status} />
                </CellBox>
            </BasicTableRowStyles>
        </Box>
    </>
}